import axios from 'axios';
import moment from 'moment';

const history = {};

const getData = async (resolution) => {
  let quoteCurrency = localStorage.getItem('quoteCurrency');
  let baseCurrency = localStorage.getItem('baseCurrency');
  let startDate = localStorage.getItem('startDate');
  let endDate = localStorage.getItem('endDate');
  console.log('startDate', startDate);

  const interval =
    resolution === '1D' ? 'hour(count: 24)' : 'minute(count: 15)';

  const sinceDate = moment.unix(startDate).format('YYYY-MM-DDThh:mm:ss');
  const tillDate = moment.unix(endDate).format('YYYY-MM-DDThh:mm:ss');
  console.log('sinceDatesinceDatesinceDate', sinceDate);
  // resolution === '1D'
  //   ? moment().subtract(9, 'months').format('YYYY-MM-DD')
  //   : moment().subtract(1, 'months').format('YYYY-MM-DD');

  const timeIntervalText =
    resolution === '1D' ? 'timeInterval.hour' : 'timeInterval.minute';

  const res = await axios.post('https://graphql.bitquery.io', {
    query: `{
      ethereum(network: bsc) {
        dexTrades(
          options: {limit: 3000, asc: "${timeIntervalText}"}
          date: { since: "${sinceDate}", till: "${tillDate}" }
          baseCurrency: {is: "${quoteCurrency}"}
          quoteCurrency: { is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" }
          exchangeAddress: { is: "0xbcfccbde45ce874adcb698cc183debcf17952812" }
        ) {
          timeInterval {
            ${interval}
          }
          baseCurrency {
            symbol
            address
          }
          baseAmount
          quoteCurrency {
            symbol
            address
          }
          quoteAmount
          trades: count
          tradeAmount(in: USDT)
          maximum_price: quotePrice(calculate: maximum)
          minimum_price: quotePrice(calculate: minimum)
          open_price: minimum(of: block, get: quote_price)
          close_price: maximum(of: block, get: quote_price)
          exchange {
            address {
              address
            }
          }
        }
      }
    }
      `,
  });

  return res.data.data.ethereum.dexTrades;
};

export default {
  history: history,

  getBars: function (symbolInfo, resolution, from, to, first, limit) {
    const interval = resolution === '1D' ? 'hour' : 'minute';
    const BNBPriceInUSD = parseFloat(localStorage.getItem('BNBPriceInUSD'));
    console.log('BNBPriceInUSD', BNBPriceInUSD);
    return getData(resolution).then((data) => {
      if (data.length) {
        var bars = data.map((el, i) => {
          return {
            time: moment(el.timeInterval[interval]).unix() * 1000, //TradingView requires bar time in ms
            low: parseFloat(el.minimum_price * BNBPriceInUSD),
            high: parseFloat(el.maximum_price * BNBPriceInUSD),
            open: parseFloat(el.open_price * BNBPriceInUSD),
            close: parseFloat(el.close_price * BNBPriceInUSD),
            volume: el.tradeAmount,
          };
        });

        if (first) {
        }
        return bars;
        // return [];
      } else {
        return [];
      }
    });
  },
};
