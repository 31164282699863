import React, { useState, useRef, useEffect, useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import ApiConfig from '../../../config/ApiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CriculerLoader from '../CustomComponent/CircularLoader';
import AddWallet from './AddWallet';
import { AuthContext } from '../../../context/Auth';
import { sortWalletAddress, sortAddress } from '../../CommonFunctions';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { el } from 'date-fns/locale';
import { Button, Modal } from 'react-bootstrap';

export default function WalletTracking() {
  const history = useHistory();
  const accessToken = window.localStorage.getItem('accessToken');
  const auth = useContext(AuthContext);
  const [isAddWalletOpen, setIsAddWalletOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState('Select');
  const [isLoading, setIsLoading] = useState(false);
  const [allWalletList, setAllWalletList] = useState();
  const [selectedWallet, setselectedWallet] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [data, setData] = useState(
    document.querySelectorAll('#transaction_table tbody tr')
  );
  const [activeName, setActiveName] = useState('Newest');
  const sort = 5;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  const [allProjectList, setallProjectList] = useState();
  const [transactionList, setTransactionList] = useState([]);
  const [transactionToDisplay, setTransactionToDisplay] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [selectedWalletList, setSelectedWalletList] = useState();
  const [isTranFound, setIsTranFound] = useState(false);
  const [tranFoundList, settranFoundList] = useState();
  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove('d-none');
      } else {
        data[i].classList.add('d-none');
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll('#transaction_table tbody tr'));
  }, [test]);

  useEffect(() => {
    if (!auth.userLoggedIn) {
      history.push('/dashboard');
    }
  }, [auth]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  const projectListHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.listProject, {
        headers: {
          token: accessToken,
        },
      });
      if (res.data.response_code === 200) {
        if (res.data.result && res.data.result.length > 0) {
          // setSelectedCoin(res.data.result[0]);
        }
        setallProjectList(res.data.result);
      } else {
        setSelectedCoin();
        setallProjectList([]);
      }
    } catch (err) {
      setSelectedCoin();
      setallProjectList([]);
      console.log('ERROR', err);
      projectListHandler();
    }
  };

  useEffect(() => {
    projectListHandler();
  }, [auth.userData.walletAddress]);

  const getWalletListHandler = async (projectId) => {
    try {
      const res = await axios.get(ApiConfig.listWallet, {
        params: {
          projectId: projectId,
        },
        headers: {
          token: accessToken,
        },
      });
      if (res.data.response_code === 200) {
        if (res.data.result && res.data.result.length > 0) {
          setselectedWallet(res.data.result[0]);
        }
        setAllWalletList(res.data.result.wallets);
        setSelectedWalletList(res.data.result.wallets);
      } else {
        setselectedWallet();
        setAllWalletList([]);
        setSelectedWalletList([]);
      }
    } catch (err) {
      console.log('ERROR', err);
      setselectedWallet();
      setAllWalletList([]);
      setSelectedWalletList([]);
      getWalletListHandler(projectId);
    }
  };

  useEffect(() => {
    if (selectedCoin && selectedCoin._id) {
      setIsLoading(true);
      getWalletListHandler(selectedCoin._id);
    }
  }, [auth.userData.walletAddress, selectedCoin]);

  const getTransactionRecursiveHandler = async (
    walletAddress,
    contractAddress,
    page
  ) => {
    try {
      const res = await axios.get(
        `https://stg-api.unmarshal.io/v1/bsc/address/${walletAddress}/transactions?auth_key=VGVtcEtleQ%3D%3D&page=${page}&pageSize=50`
      );
      if (res.status === 200) {
        return res.data.transactions;
      } else {
        return [];
      }
    } catch (err) {
      console.log('ERROR', err);
      return [];
    }
  };

  const getTransactionHandler = async (walletAddress, contractAddress) => {
    console.log('contractAddress', contractAddress);
    try {
      const res = await axios.get(
        `https://stg-api.unmarshal.io/v1/bsc/address/${walletAddress}/transactions?auth_key=VGVtcEtleQ%3D%3D&page=1&pageSize=50`
      );
      if (res.status === 200) {
        let resultArr = [];
        const total_pages = res.data.total_pages;
        let finalTransactionList = res.data.transactions;
        for (let page = 2; page <= total_pages; page++) {
          let tempTransData = await getTransactionRecursiveHandler(
            walletAddress,
            contractAddress,
            page
          );
          let tempConcat = finalTransactionList.concat(tempTransData);
          finalTransactionList = tempConcat;
        }

        for (let i = 0; i < finalTransactionList.length; i++) {
          if (finalTransactionList[i].received) {
            for (
              let rec = 0;
              rec < finalTransactionList[i].received.length;
              rec++
            ) {
              if (
                finalTransactionList[i].received[rec].token_id ==
                contractAddress
                // Object.values(finalTransactionList[i].received[rec]).indexOf(
                //   contractAddress.toLowerCase()
                // ) > -1 ||
                // Object.values(finalTransactionList[i].received[rec]).indexOf(
                //   contractAddress
                // ) > -1
              ) {
                resultArr.push({
                  data: finalTransactionList[i],
                  isSent:
                    finalTransactionList[i].received[rec].to == walletAddress
                      ? false
                      : true,
                });
              } else {
                if (finalTransactionList[i].sent) {
                  for (
                    let sen = 0;
                    sen < finalTransactionList[i].sent.length;
                    sen++
                  ) {
                    if (
                      finalTransactionList[i].sent[sen].token_id ==
                      contractAddress
                      // Object.values(finalTransactionList[i].sent[sen]).indexOf(
                      //   contractAddress.toLowerCase()
                      // ) > -1 ||
                      // Object.values(finalTransactionList[i].sent[sen]).indexOf(
                      //   contractAddress
                      // ) > -1
                    ) {
                      resultArr.push({
                        data: finalTransactionList[i],
                        isSent:
                          finalTransactionList[i].sent[sen].to == walletAddress
                            ? false
                            : true,
                      });
                    }
                  }
                } else {
                  if (finalTransactionList[i].others) {
                    for (
                      let oth = 0;
                      oth < finalTransactionList[i].others.length;
                      oth++
                    ) {
                      if (
                        finalTransactionList[i].others[oth].token_id ==
                        contractAddress

                        // Object.values(
                        //   finalTransactionList[i].others[oth]
                        // ).indexOf(contractAddress.toLowerCase()) > -1 ||
                        // Object.values(
                        //   finalTransactionList[i].others[oth]
                        // ).indexOf(contractAddress) > -1
                      ) {
                        resultArr.push({
                          data: finalTransactionList[i],
                          isSent:
                            finalTransactionList[i].type == 'receive'
                              ? false
                              : finalTransactionList[i].sent
                              ? true
                              : false,
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        }

        return resultArr;
      } else {
        // setTransactionList([]);
        return [];
      }
    } catch (err) {
      console.log('ERROR', err);
      return [];
    }
  };

  const getAllWalletTransactionData = async (
    allWalletList,
    selectedCoin,
    isCheck = false
  ) => {
    let dataArr = [];
    if (
      allWalletList &&
      allWalletList.length > 0 &&
      selectedCoin &&
      selectedCoin.contractAddress
    ) {
      for (let i = 0; i < allWalletList.length; i++) {
        let tranArr = await getTransactionHandler(
          allWalletList[i].walletAddress,
          selectedCoin.contractAddress
        );
        let tempDataArr = dataArr.concat(tranArr);
        dataArr = tempDataArr;
      }
      await dataArr.sort(function (a, b) {
        if (
          startDate !== '' &&
          endDate !== '' &&
          a.data.date >= startDate &&
          a.data.date <= endDate
        ) {
          return b.data.date - a.data.date;
        }
      });

      if (isCheck) {
        return dataArr;
      } else {
        setTimeout(() => {
          setTransactionList(dataArr);
          setTransactionToDisplay(dataArr);
          setIsLoading(false);
          setTimeout(() => {
            setData(document.querySelectorAll('#transaction_table tbody tr'));
          }, 100);
        }, 500);
      }
    } else {
      setTimeout(() => {
        setTransactionList(dataArr);
        setTransactionToDisplay(dataArr);
        setIsLoading(false);
        setTimeout(() => {
          setData(document.querySelectorAll('#transaction_table tbody tr'));
        }, 100);
      }, 500);
    }
  };

  useEffect(() => {
    if (
      selectedCoin !== 'Select' &&
      selectedWalletList &&
      selectedWalletList.length > 0
    ) {
      setIsLoading(true);
      getAllWalletTransactionData(selectedWalletList, selectedCoin);
    } else {
      setTransactionList();
      setTransactionToDisplay();
      setIsLoading(false);
    }
  }, [selectedWalletList]);

  useEffect(() => {
    if (transactionList && transactionList.length > 0) {
      allFilter(activeName, startDate, endDate);
    }
  }, [activeName, startDate, endDate, transactionList]);

  const sortByPeriodHandler = async (activeName, transactionList) => {
    if (transactionList && transactionList.length > 0) {
      setTransactionToDisplay();
      // setIsUpdating(true);
      let dataSet = transactionList;
      if (activeName === 'Oldest') {
        await dataSet.sort(function (a, b) {
          return a.data.date - b.data.date;
        });
        return dataSet;
        // setTimeout(() => {
        //   setTransactionToDisplay(dataSet);
        //   setIsUpdating(false);
        // }, 500);
        // setTimeout(() => {
        //   setData(document.querySelectorAll('#transaction_table tbody tr'));
        // }, 550);
      } else {
        await dataSet.sort(function (a, b) {
          return b.data.date - a.data.date;
        });

        return dataSet;

        // setTimeout(() => {
        //   setTransactionToDisplay(dataSet);
        //   setIsUpdating(false);
        //   setTimeout(() => {
        //     setData(document.querySelectorAll('#transaction_table tbody tr'));
        //   }, 100);
        // }, 500);
      }
    }
  };

  const sortByDatehandler = async (startDate, endDate, transactionList) => {
    if (transactionList && transactionList.length > 0) {
      // setIsUpdating(true);

      let dataSet = transactionList;
      const resultdata = dataSet.filter(
        (data) => data.data.date >= startDate && data.data.date <= endDate
      );

      return resultdata;
      // setTimeout(() => {
      //   setTransactionToDisplay(resultdata);
      //   setIsUpdating(false);
      //   setTimeout(() => {
      //     setData(document.querySelectorAll('#transaction_table tbody tr'));
      //   }, 100);
      // }, 500);
    } else {
      return transactionList;
    }
  };

  const allFilter = async (activeName, startDate, endDate) => {
    setIsUpdating(true);
    let allData = transactionList;
    if (transactionList && transactionList.length > 0) {
      allData = await sortByPeriodHandler(activeName, allData);
      if (startDate !== '' && endDate !== '') {
        allData = await sortByDatehandler(startDate, endDate, allData);
      }
    }
    setTimeout(() => {
      setTransactionToDisplay(allData);
      setIsUpdating(false);
      setTimeout(() => {
        setData(document.querySelectorAll('#transaction_table tbody tr'));
      }, 50);
    }, 500);
  };

  // useEffect(() => {
  //   if (startDate !== '' && endDate !== '') {
  //     sortByDatehandler(startDate, endDate);
  //   } else {
  //     setIsUpdating(true);
  //     setTimeout(() => {
  //       setTransactionToDisplay(transactionList);
  //       setIsUpdating(false);
  //       setTimeout(() => {
  //         setData(document.querySelectorAll('#transaction_table tbody tr'));
  //       }, 50);
  //     }, 500);
  //   }
  // }, [startDate, endDate]);

  const handleEvent = (event, picker) => {
    if (event.type === 'cancel') {
      setStartDate('');
      setEndDate('');
    } else if (event.type === 'apply') {
      setStartDate(moment(picker.startDate).unix());
      setEndDate(moment(picker.endDate).unix());
    }
  };
  const handleCallback = (start, end, label, event) => {
    // setStartDate(moment(start).unix());
    // setEndDate(moment(end).unix());
  };

  const getCheckedItem = () => {
    var checkboxes = document.querySelectorAll(
      `input[name=walletAddress]:checked`
    );
    let wallArr = [];
    for (let i = 0; i < checkboxes.length; i++) {
      wallArr.push({ walletAddress: checkboxes[i].id });
    }
    setTimeout(() => {
      setSelectedWalletList(wallArr);
    }, 100);
  };

  const checkTransaction = async (walletData) => {
    setIsTranFound(false);
    let dataArr = [];
    let coinList = [];
    let tranList = await getAllWalletTransactionData(
      walletData,
      selectedCoin,
      true
    );

    let tempDataArr = dataArr.concat(tranList);
    dataArr = tempDataArr;

    setTimeout(() => {
      settranFoundList(coinList);
      setIsLoading(false);
      if (dataArr.length > 0) {
        setIsTranFound(true);
      }
    }, 100);

    if (dataArr.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {isAddWalletOpen && (
        <AddWallet
          show={isAddWalletOpen}
          onHide={() => {
            setIsLoading(false);
            setIsAddWalletOpen(false);
          }}
          getWalletListHandler={(data) => getWalletListHandler(data._id)}
          checkTranHandler={(walletData) => checkTransaction(walletData)}
          isTranFound={isTranFound}
          tranFoundList={tranFoundList}
          selectedCoin={selectedCoin}
        />
      )}
      <div className="row">
        <div className="col-xl-5">
          <div className="row">
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="form-control style-1  mb-3 "
                style={{
                  minWidth: 250,
                  textAlign: 'left',
                  borderRadius: '2rem',
                }}
              >
                {selectedCoin && selectedCoin === 'Select'
                  ? selectedCoin
                  : selectedCoin && selectedCoin.coinName}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSelectedCoin('Select')}>
                  Select
                </Dropdown.Item>
                {allProjectList &&
                  allProjectList.length > 0 &&
                  allProjectList.map((data, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        onClick={() => setSelectedCoin(data)}
                      >
                        {data.coinName}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="col-xl-7">
          <div className="row">
            {/* <div className="col-xl-3">
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  type="button"
                  className="form-control style-1  mb-3 "
                  data-toggle="dropdown"
                  role="button"
                  style={{
                    borderRadius: '2rem',
                  }}
                >
                  {selectedWallet &&
                    sortWalletAddress(selectedWallet.walletAddress)}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu " role="combobox">
                  {allWalletList &&
                    allWalletList.length > 0 &&
                    allWalletList.map((data, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          onClick={() => setselectedWallet(data)}
                        >
                          {sortWalletAddress(data.walletAddress)}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            <div className="col-xl-4">
              <button
                onClick={() => {
                  if (selectedCoin !== 'Select') {
                    setIsAddWalletOpen(true);
                  } else {
                    setIsErrorPopUpOpen(true);
                  }
                }}
                className="btn btn-primary btn-rounded mb-3 mr-3"
                style={{ width: '100%' }}
              >
                Add Wallet
              </button>
            </div>
            <div className="col-xl-4">
              <button
                type="button"
                className="btn  btn-outline-light btn-rounded"
                style={{ width: '100%', borderColor: '#d7dae3' }}
                onClick={() => {
                  if (isFilter) {
                    setStartDate('');
                    setEndDate('');
                  }
                  setIsFilter(!isFilter);
                }}
              >
                <i className="las la-calendar scale5 mr-2" />
                Filter
              </button>
            </div>
            <div className="col-xl-4">
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  type="button"
                  className="form-control style-1  mb-3 "
                  data-toggle="dropdown"
                  role="button"
                  style={{
                    // textAlign: 'left',
                    borderRadius: '2rem',
                  }}
                >
                  {activeName}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu " role="combobox">
                  <Dropdown.Item onClick={() => setActiveName('Newest')}>
                    Newest
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setActiveName('Oldest')}>
                    Oldest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      {isFilter && (
        <div className="row mt-3">
          <div className="col-xl-9">
            <h4 className="mb-2">Filter by wallet</h4>
            <div className="row" style={{ height: 65, overflowY: 'auto' }}>
              {allWalletList &&
                allWalletList.length > 0 &&
                allWalletList.map((data, i) => {
                  return (
                    <div
                      className="col-6 col-sm-4 col-md-3"
                      key={data.walletAddress}
                    >
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="custom-control-input"
                          id={data.walletAddress}
                          name="walletAddress"
                          onChange={() => getCheckedItem()}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={data.walletAddress}
                        >
                          {sortAddress(data.walletAddress)}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-xl-3 text-right">
            <h4 className="mb-2 text-center">Filter by date</h4>
            <DateRangePicker
              onEvent={handleEvent}
              onCallback={handleCallback}
              // initialSettings={{
              //   startDate: moment().subtract(1, 'months'),
              //   endDate: new Date(),
              // }}
            >
              <button
                type="button"
                className="btn  btn-outline-light btn-rounded"
                style={{ borderColor: '#d7dae3' }}
              >
                <i className="las la-calendar scale5 mr-3" />
                Filter Periode
              </button>
            </DateRangePicker>
          </div>
        </div>
      )}
      <hr />
      {!isLoading && transactionToDisplay && transactionToDisplay.length > 0 ? (
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive table-hover fs-14">
              <div
                id="example5_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer"
                  id="transaction_table"
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting_asc"></th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Transaction ID: activate to sort column ascending"
                        style={{ width: 124 }}
                      >
                        Transaction ID
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Date: activate to sort column ascending"
                        style={{ width: '54.6667px' }}
                      >
                        Date
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="From: activate to sort column ascending"
                        style={{ width: 62 }}
                      >
                        From
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="To: activate to sort column ascending"
                        style={{ width: 110 }}
                      >
                        To
                      </th>

                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Amount: activate to sort column ascending"
                        style={{ width: '58.6667px' }}
                      >
                        Amount
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Note: activate to sort column ascending"
                        style={{ width: '143.333px' }}
                      >
                        Note
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionToDisplay &&
                      transactionToDisplay.length > 0 &&
                      transactionToDisplay.map((resData, i) => {
                        const data = resData.data;
                        return (
                          <tr
                            role="row"
                            className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`}
                            key={i}
                            onClick={() =>
                              window.open(`https://bscscan.com/tx/${data.id}`)
                            }
                          >
                            <td className="pr-0 sorting_1 mr-1">
                              {resData.isSent ? (
                                <span className="bg-danger ic-icon">
                                  <svg
                                    width={29}
                                    height={29}
                                    viewBox="0 0 29 29"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.99954 10.4687L21.1821 23.6513C21.7435 24.2127 22.6537 24.2127 23.2151 23.6513C23.7765 23.0899 23.7765 22.1798 23.2151 21.6184L10.0325 8.43582L13.4612 8.4441L13.4612 8.44409C14.2551 8.44598 14.9002 7.80394 14.9021 7.01004C14.904 6.21593 14.2617 5.57098 13.468 5.56905L13.4668 6.06905L13.468 5.56905L6.55703 5.55234L6.54969 5.55232L6.54737 5.55239C5.75771 5.55578 5.11953 6.19662 5.11616 6.98358L5.1161 6.98585L5.11612 6.99333L5.13282 13.9043L5.13282 13.9043C5.13475 14.6982 5.77979 15.3403 6.57378 15.3384C7.36769 15.3365 8.00975 14.6914 8.00787 13.8975L8.00787 13.8975L7.99954 10.4687Z"
                                      fill="white"
                                      stroke="white"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <span className="bg-success ic-icon">
                                  <svg
                                    width={29}
                                    height={29}
                                    viewBox="0 0 29 29"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M20.7529 19.1563L7.5703 5.97367C7.00891 5.41228 6.09876 5.41228 5.53737 5.97367C4.97598 6.53505 4.97598 7.44521 5.53737 8.0066L18.72 21.1892L15.2913 21.1809L15.2912 21.1809C14.4973 21.179 13.8522 21.8211 13.8503 22.615C13.8484 23.4091 14.4907 24.054 15.2844 24.056L15.2856 23.556L15.2844 24.056L22.1954 24.0727L22.2028 24.0727L22.2051 24.0726C22.9947 24.0692 23.6329 23.4284 23.6363 22.6414L23.6363 22.6391L23.6363 22.6317L23.6196 15.7207L23.6196 15.7207C23.6177 14.9268 22.9727 14.2847 22.1787 14.2866C21.3847 14.2885 20.7427 14.9336 20.7446 15.7275L20.7446 15.7275L20.7529 19.1563Z"
                                      fill="white"
                                      stroke="white"
                                    />
                                  </svg>
                                </span>
                              )}
                            </td>
                            <td>#{sortAddress(data.id)}</td>
                            <td>
                              {moment(new Date(data.date * 1000)).format(
                                'DD/MM/YYYY hh:mm a'
                              )}
                            </td>
                            <td>{sortAddress(data.from)}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span>{sortAddress(data.to)}</span>
                              </div>
                            </td>

                            <td>
                              <span className="text-black font-w500">
                                {data.value !== '0'
                                  ? parseFloat(data.value) / 1000000000000000000
                                  : data.value}{' '}
                                BNB
                              </span>
                            </td>
                            <td
                              style={{ maxWidth: 100, wordBreak: 'break-word' }}
                            >
                              <p className="mb-0">
                                {data.description}
                                {/* <Link
                                  to="/transactions"
                                  className="text-secondary"
                                >
                                  More
                                </Link> */}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {activePag.current * sort + 1} to{' '}
                    {data.length > (activePag.current + 1) * sort
                      ? (activePag.current + 1) * sort
                      : data.length}{' '}
                    of {data.length} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                      to="/wallet-tracking"
                      onClick={() =>
                        activePag.current > 0 && onClick(activePag.current - 1)
                      }
                    >
                      Previous
                    </Link>
                    <span>
                      {paggination.map((number, i) => (
                        <Link
                          key={i}
                          to="/wallet-tracking"
                          className={`paginate_button  ${
                            activePag.current === i ? 'current' : ''
                          } `}
                          onClick={() => onClick(i)}
                          style={{ display: 'inline-block' }}
                        >
                          {number}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="/wallet-tracking"
                      onClick={() =>
                        activePag.current + 1 < paggination.length &&
                        onClick(activePag.current + 1)
                      }
                    >
                      Next
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : !isLoading && !isUpdating ? (
        <div className="row">
          <div className="col-xl-12">
            <h3 className="text-center mt-5">
              {allProjectList && allProjectList.length === 0
                ? 'Please add project'
                : selectedWalletList && selectedWalletList.length === 0
                ? 'Please add/Select wallet'
                : selectedCoin !== 'Select'
                ? 'Search not found'
                : 'Please select coin and Filter Period to see transaction'}
            </h3>
          </div>
        </div>
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}
        >
          <div class="loader"></div>
        </div>
      )}
      <Modal
        show={isErrorPopUpOpen}
        onHide={() => setIsErrorPopUpOpen(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please select Coin/Project</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsErrorPopUpOpen(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
