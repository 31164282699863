import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import {
  isBlank,
  isUrlValid,
  isUrlValidTelegram,
} from '../../CommonFunctions/';
import axios from 'axios';
import ApiConfig from '../../../config/ApiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CriculerLoader from '../CustomComponent/CircularLoader';

export default function AddProjectModal(props) {
  const coinDetails = props.coinDetails;
  const accessToken = window.localStorage.getItem('accessToken');
  const [isUpdating, setIsUpdating] = useState(false);
  const [formValue, setFormValue] = useState({
    contractAddress: '',
    website: '',
    tokenType: '',
    launchDate: new Date(),
    community: '',
    coinImage: '',
    coinName: '',
    price: '',
    description: '',
    // isMarketCap: false,
    // marketCapUrl: '',
    // isCoinGecko: false,
    // coinGeckoUrl: '',
    coinShortName: '',
    _id: '',
  });

  const [projectLogoBuld, setProjectLogoBuld] = useState('');
  const [newProfilePic, setNewProfilePic] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (coinDetails && coinDetails.contractAddress) {
      setProjectLogoBuld(coinDetails.coinImage);
      setFormValue({
        contractAddress: coinDetails.contractAddress,
        website: coinDetails.website,
        tokenType: coinDetails.tokenType,
        launchDate: new Date(coinDetails.launchDate),
        community: coinDetails.community,
        coinName: coinDetails.coinName,
        price: coinDetails.price,
        description: coinDetails.description,
        // isMarketCap: false,
        // marketCapUrl: '',
        // isCoinGecko: false,
        // coinGeckoUrl: '',
        coinShortName: coinDetails.coinShortName,
        _id: coinDetails._id,
      });
    }
  }, [coinDetails]);

  const _onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const temp = { ...formValue, [name]: value };
    setFormValue(temp);
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (err) {
      console.log('Error: ', err);
    };
  };

  const _onProfilePicChange = (e) => {
    console.log('eeee', e);
    const name = e.target.name;
    const value = URL.createObjectURL(e.target.files[0]);
    setProjectLogoBuld(value);
    getBase64(e.target.files[0], (result) => {
      console.log('result', result);
      const temp = { ...formValue, [name]: result };
      console.log('temp', temp);
      setFormValue(temp);
      setNewProfilePic(value);
    });
  };

  const getTokenDetailshandlerOld = async (address) => {
    if (address.length > 15) {
      setIsLoading(true);
      try {
        const res = await axios.get(ApiConfig.tokenDetails, {
          params: {
            address,
          },
        });
        console.log('getTokenDetailshandler', res);
        setIsLoading(false);

        if ((res.data.responseCode = 200 && res.data.responseResult.data)) {
          const data =
            res.data.responseResult.data.address[0].smartContract.currency;

          getcoinPriceHandler(address, data);
        } else {
          notifyTopRight(res.data.responseMessage, 'warn');

          // getTokenDetailshandler(address);
        }
      } catch (err) {
        setIsLoading(false);

        console.log('ERROR', err);
        // getTokenDetailshandler(address);
      }
    }
  };

  const getTokenDetailshandler = async (address) => {
    if (address.length > 15) {
      setIsLoading(true);

      try {
        // const res = await axios.get(ApiConfig.tokenDetails, {
        //   params: {
        //     address,
        //   },
        // });
        var res = await axios({
          method: 'post',
          url: `https://graphql.bitquery.io`,
          data: {
            query: `
            query MyQuery {
              ethereum(network: bsc) {
                address(address: {is: "${address}"}) {
                  smartContract {
                    currency {
                      address
                      decimals
                      name
                      symbol
                      tokenId
                    }
                  }
                }
              }
            }
             `,
          },
        });
        console.log('getTokenDetailshandler', res);

        if (res.status === 200 && res.data.data.ethereum) {
          const data = res.data.data.ethereum.address[0].smartContract.currency;
          getcoinPriceHandler(address, data);
        } else {
          setIsLoading(false);
          notifyTopRight(
            'Something went wrong, Please enter valid details',
            'warn'
          );

          // getTokenDetailshandler(address);
        }
      } catch (err) {
        setIsLoading(false);
        console.log('ERROR', err);
        // getTokenDetailshandler(address);
      }
    }
  };

  const getcoinPriceHandler = async (baseCurrency, data) => {
    try {
      // const res = await axios.get(ApiConfig.tokenPrice, {
      //   params: {
      //     baseCurrency: baseCurrency,
      //   },
      // });
      const result1 = await axios.post('https://graphql.bitquery.io', {
        json: true,
        query: `{
                              ethereum(network: bsc) {
                                dexTrades(
                                  options: {desc: ["block.height", "transaction.index"], limit: 1}
                                  baseCurrency: {is: "${baseCurrency}"}
                                  quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"}
                                ) {
                                  block {
                                    height
                                    timestamp {
                                      time(format: "%Y-%m-%d %H:%M:%S")
                                    }
                                  }
                                  transaction {
                                    index
                                  }
                                  baseCurrency {
                                    symbol
                                  }
                                  quoteCurrency {
                                    symbol
                                  }
                                  quotePrice
                                }
                              }
                            }
                            `,
      });
      const result2 = await axios.post('https://graphql.bitquery.io', {
        json: true,
        query: `{
                      ethereum(network: bsc) {
                        dexTrades(
                          options: {desc: ["block.height", "transaction.index"], limit: 1}
                          baseCurrency: {is: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"}
                          quoteCurrency: {is: "0x55d398326f99059ff775485246999027b3197955"}
                        ) {
                          block {
                            height
                            timestamp {
                              time(format: "%Y-%m-%d %H:%M:%S")
                            }
                          }
                          transaction {
                            index
                          }
                          baseCurrency {
                            symbol
                          }
                          quoteCurrency {
                            symbol
                          }
                          quotePrice
                        }
                      }
                    }
                    
                            `,
      });
      console.log('result1', result1);
      console.log('result2', result2);

      if (result1.status === 200 && result2.status === 200) {
        let TokenPrice =
          result1.data.data.ethereum.dexTrades[0].quotePrice *
          result2.data.data.ethereum.dexTrades[0].quotePrice;

        // let PriceInBNB = result1.data.data.ethereum.dexTrades[0].quotePrice;
        setIsLoading(false);
        const temp = {
          ...formValue,
          contractAddress: baseCurrency,
          price: TokenPrice,
          coinName: data.name,
          coinShortName: data.symbol,
        };
        setFormValue(temp);
        notifyTopRight('Success', 'success');
      } else {
        notifyTopRight('Something went wrong', 'warn');
      }
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  const getcoinPriceHandlerOld = async (baseCurrency, data) => {
    try {
      const res = await axios.get(ApiConfig.tokenPrice, {
        params: {
          baseCurrency: baseCurrency,
        },
      });
      console.log('RES', res);

      if (res.data.responseCode === 200) {
        const temp = {
          ...formValue,
          contractAddress: baseCurrency,
          price: res.data.responseResult.TokenPrice,
          coinName: data.name,
          coinShortName: data.symbol,
        };
        setFormValue(temp);
        notifyTopRight(res.data.responseMessage, 'success');
      } else {
        notifyTopRight(res.data.responseMessage, 'warn');
      }
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  // const addProjectHandler = async () => {
  //   console.log('formValue', formValue);
  // };

  const addProjectHandler = async () => {
    setIsSubmit(true);
    console.log('projectLogoBuld', projectLogoBuld);
    try {
      if (
        // !isBlank(formValue.coinName) &&
        !isBlank(formValue.contractAddress) &&
        // !isBlank(formValue.tokenType) &&
        // !isBlank(formValue.price) &&
        // parseFloat(formValue.price) > 0 &&
        // !isBlank(formValue.description) &&
        // projectLogoBuld !== '' &&
        (formValue.community === '' ||
          (formValue.community !== '' &&
            isUrlValidTelegram(formValue.community))) &&
        (formValue.website === '' ||
          (formValue.website !== '' && isUrlValid(formValue.website)))
      ) {
        setIsUpdating(true);
        const res = await axios.put(ApiConfig.editProject, formValue, {
          headers: {
            token: accessToken,
          },
        });
        console.log('res', res.data.response_code);
        setIsUpdating(false);

        if (res.data.response_code === 200) {
          props.projectListHandler();
          notifyTopRight(res.data.response_message, 'success');
          setFormValue({
            contractAddress: '',
            website: '',
            tokenType: '',
            launchDate: new Date(),
            community: '',
            coinImage: '',
            coinName: '',
            price: '',
            description: '',
            // isMarketCap: false,
            // marketCapUrl: '',
            // isCoinGecko: false,
            // coinGeckoUrl: '',
            coinShortName: '',
          });
          setProjectLogoBuld('');
          setNewProfilePic('');
          setIsSubmit(false);
          props.onHide();
        } else {
          notifyTopRight(res.data.response_message, 'warn');
        }
      }
    } catch (err) {
      console.log('ERROR', err);
      setIsUpdating(false);
    }
  };

  const notifyTopRight = (msg, type) => {
    toast[type](msg, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <>
      <ToastContainer />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3">
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Enter contract address"
                name="contractAddress"
                value={formValue.contractAddress}
                onChange={(e) => {
                  _onInputChange(e);
                  getTokenDetailshandler(e.target.value);
                }}
                maxLength={50}
              />
              {isSubmit && isBlank(formValue.contractAddress) && (
                <p className="text-danger">Enter contract address</p>
              )}
            </div>
            <div className="col-sm-6">
              {' '}
              <input
                type="text"
                className="form-control"
                placeholder="Enter coin name"
                name="coinName"
                value={formValue.coinName}
                onChange={(e) => _onInputChange(e)}
                maxLength={50}
                readOnly
              />
              {/* {isSubmit && isBlank(formValue.coinName) && (
                <p className="text-danger">Enter coin name</p>
              )} */}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6">
              {' '}
              <input
                type="text"
                className="form-control"
                placeholder="Enter coin short name"
                name="coinShortName"
                value={formValue.coinShortName}
                onChange={(e) => _onInputChange(e)}
                maxLength={50}
                readOnly
              />
              {/* {isSubmit && isBlank(formValue.coinShortName) && (
                <p className="text-danger">Enter coin short name"</p>
              )} */}
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Enter token type"
                name="tokenType"
                value={formValue.tokenType}
                onChange={(e) => _onInputChange(e)}
                maxLength={50}
                readOnly
              />
              {/* {isSubmit && isBlank(formValue.tokenType) && (
                <p className="text-danger">Enter token type</p>
              )} */}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Enter community URL (Telegram)"
                name="community"
                value={formValue.community}
                onChange={(e) => _onInputChange(e)}
                maxLength={50}
              />
              {isSubmit &&
                formValue.community !== '' &&
                !isUrlValidTelegram(formValue.community) && (
                  <p className="text-danger">Enter community</p>
                )}
            </div>
            <div className="col-sm-6">
              {' '}
              <input
                type="text"
                className="form-control"
                placeholder="Enter website URL"
                name="website"
                value={formValue.website}
                onChange={(e) => _onInputChange(e)}
                maxLength={50}
              />
              {isSubmit &&
                formValue.website !== '' &&
                !isUrlValid(formValue.website) && (
                  <p className="text-danger">Enter website URL</p>
                )}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6">
              <lable className="mb-2">Select coin logo</lable>
              <input
                valu={newProfilePic}
                name="coinImage"
                onChange={(e) => _onProfilePicChange(e)}
                type="file"
                // className="form-control"
                accept="image/png,image/jpeg"
              />
              {/* {isSubmit && isBlank(projectLogoBuld) && (
                <p className="text-danger">Select coin logo</p>
              )} */}
              {projectLogoBuld !== '' && (
                <div className="form-group col-md-4 mt-2">
                  <img src={projectLogoBuld} alt="" width="100" />
                </div>
              )}
            </div>
            <div className="col-sm-6">
              {' '}
              <input
                type="number"
                className="form-control"
                placeholder="Enter price"
                name="price"
                value={formValue.price}
                onChange={(e) => _onInputChange(e)}
                readOnly
              />
              {/* {isSubmit &&
                (isBlank(formValue.price) ||
                  (!isBlank(formValue.price) &&
                    parseFloat(formValue.price) < 0)) && (
                  <p className="text-danger">Enter price</p>
                )} */}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6">
              <input
                type="date"
                className="form-control"
                placeholder="Enter project name"
                name="launchDate"
                value={moment(formValue.launchDate).format('YYYY-MM-DD')}
                onChange={(e) => _onInputChange(e)}
                maxLength={50}
              />
              {/* {isSubmit && isBlank(formValue.launchDate) && (
              <p className="text-danger">Enter project name</p>
            )} */}
            </div>

            <div className="col-sm-6">
              {' '}
              <textarea
                type="text"
                className="form-control"
                placeholder="Enter description"
                name="description"
                value={formValue.description}
                onChange={(e) => _onInputChange(e)}
                maxLength={255}
              />
              {/* {isSubmit && isBlank(formValue.description) && (
                <p className="text-danger">Enter description</p>
              )} */}
            </div>
          </div>

          {/* <div className="row mt-3">
            <div className="col-sm-6">
              {' '}
              <div className="custom-control custom-checkbox mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckBox2"
                  onClick={() => {
                    const temp = {
                      ...formValue,
                      isMarketCap: !formValue.isMarketCap,
                    };
                    setFormValue(temp);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckBox2"
                >
                  Is Market Cap
                </label>
              </div>
            </div>
            {formValue.isMarketCap && (
              <div className="col-sm-6">
                {' '}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter market cap URL"
                  name="marketCapUrl"
                  value={formValue.marketCapUrl}
                  onChange={(e) => _onInputChange(e)}
                  maxLength={50}
                />
                {isSubmit &&
                  formValue.marketCapUrl !== '' &&
                  !isUrlValid(formValue.marketCapUrl) && (
                    <p className="text-danger">Enter market cap URL</p>
                  )}
              </div>
            )}
          </div> */}

          {/* <div className="row mt-3">
            <div className="col-sm-6">
              {' '}
              <div className="custom-control custom-checkbox mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckBox1"
                  onClick={() => {
                    const temp = {
                      ...formValue,
                      isCoinGecko: !formValue.isCoinGecko,
                    };
                    setFormValue(temp);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckBox1"
                >
                  Is Coin Gecko
                </label>
              </div>
            </div>
            {formValue.isCoinGecko && (
              <div className="col-sm-6">
                {' '}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Coin Gecko URL"
                  name="coinGeckoUrl"
                  value={formValue.coinGeckoUrl}
                  onChange={(e) => _onInputChange(e)}
                  maxLength={50}
                />
                {isSubmit &&
                  formValue.coinGeckoUrl !== '' &&
                  !isUrlValid(formValue.coinGeckoUrl) && (
                    <p className="text-danger">Enter market cap URL</p>
                  )}
              </div>
            )}
          </div> */}

          <div className="mt-3"></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isUpdating || isLoading}
            onClick={() => {
              addProjectHandler();
            }}
            style={{ display: 'flex' }}
          >
            Submit {isUpdating && <CriculerLoader />}
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
