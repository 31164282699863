import moment from 'moment';

export const getDateDiff = (startDate, endDate) => {
  var delta = Math.abs(endDate - startDate) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = parseInt(delta % 60); // in theory the modulus is not required

  return days > 0
    ? `+ ${days} days`
    : hours > 0
    ? `${hours} h ${minutes} m ${seconds} s`
    : `${minutes} m ${seconds} s`;
};

export function isValidEmail(value) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}
export function isValidPassword(value) {
  var re = /^(?=.*\d)(?=.*[A-Z]).{8,14}$/;
  return re.test(value);
}
export function isValidNumber(value) {
  const re = /^[0-9]*$/;
  return re.test(value);
}

export function isBlank(value) {
  return value === null || value.match(/^ *$/) !== null;
}

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 6)}`;
  return sortAdd;
}

export function sortWalletAddress(add) {
  const sortAdd = `${add.slice(0, 4)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

function convert(n) {
  var sign = +n < 0 ? '-' : '',
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./);
  return +pow < 0
    ? sign +
        '0.' +
        '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
}

export function sortAmount(data) {
  let arr = data.split('e');
  const add = convert(data);
  const sortAdd = `${add.slice(0, 3)}...${add.slice(
    add.length - Math.abs(arr[1]) - 5,
    add.length - Math.abs(arr[1]) - 5 + 4
  )}`;
  return sortAdd;
}

export function isUrlValid(userInput) {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
}

export function isUrlValidTelegram(userInput) {
  var res = userInput.match(
    /https?:\/\/(t(elegram)?\.me|telegram\.org)\/([A-Za-z0-9\_]{5,32})\/?/g
  );
  if (res == null) return false;
  else return true;
}

export function TostMsgFun() {
  var x = document.getElementById('snackbar');
  console.log('XXXXXXXXXXXXX', x);
  if (x) {
    x.className = 'show';
    setTimeout(function () {
      x.className = x.className.replace('show', '');
    }, 2000);
  }
}
