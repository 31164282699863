import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

export function CoinmarketcapWidget({ bgColor, id }) {
  const refC = useRef();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://files.coinmarketcap.com/static/widget/currency.js';
    script.async = true;
    script.innerHTML =
      /* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
      refC.current.appendChild(script);
  }, [id]);

  return (
    <div
      className="coinmarketcap-currency-widget"
      data-currencyid={id}
      data-base="USD"
      data-secondary="BTC"
      data-ticker="true"
      data-rank="true"
      data-marketcap="true"
      data-volume="true"
      data-statsticker="true"
      data-stats="USD"
      ref={refC}
    ></div>
  );
}

export function CoingeckoWidget({ bgColor, id }) {
  const ref = useRef();

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://widgets.coingecko.com/coingecko-coin-ticker-widget.js';
    script.async = true;
    script.innerHTML =
      /* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
      ref.current.appendChild(script);
  }, [id]);
  return (
    <div>
      {/* <script src="https://widgets.coingecko.com/coingecko-coin-ticker-widget.js"></script> */}
      <coingecko-coin-ticker-widget
        coin-id={id}
        currency="usd"
        locale="en"
        // background-color={bgColor}
        // border-radius='5%'
        // border-width='2px'
        // border-color='#e1e5ea'
        ref={ref}
      ></coingecko-coin-ticker-widget>
    </div>
  );
}

export function CardDetails({ bgColor }) {
  return (
    <div>
      <div
        className="card border coin-holding-card"
        style={{ border: `1px solid ${bgColor}` }}
      >
        <div className="card-body rounded" style={{ backgroundColor: bgColor }}>
          <div className="d-flex align-items-center">
            <svg
              className="mr-3"
              width={42}
              height={42}
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.5567 23.893C21.1992 24.0359 20.8009 24.0359 20.4434 23.893L16.6064 22.3582L21.0001 31.1454L25.3937 22.3582L21.5567 23.893Z"
                fill="white"
              />
              <path
                d="M20.9998 20.8846L26.2769 18.7739L20.9998 10.3304L15.7227 18.7739L20.9998 20.8846Z"
                fill="white"
              />
              <path
                d="M20.9999 0.00012207C9.40201 0.00012207 0 9.40213 0 21C0 32.5979 9.40201 41.9999 20.9999 41.9999C32.5978 41.9999 41.9998 32.5979 41.9998 21C41.987 9.40762 32.5923 0.0129395 20.9999 0.00012207ZM29.8416 20.171L22.3416 35.171C21.9713 35.9121 21.0699 36.2124 20.3293 35.8421C20.0386 35.697 19.8033 35.4617 19.6582 35.171L12.1582 20.171C11.9252 19.7032 11.9518 19.1479 12.2282 18.7043L19.7282 6.70453C20.2267 6.00232 21.1995 5.83661 21.9017 6.33511C22.045 6.43674 22.17 6.56125 22.2716 6.70453L29.7711 18.7043C30.0481 19.1479 30.0746 19.7032 29.8416 20.171Z"
                fill="white"
              />
            </svg>
            <div>
              <h4 className="font-w500 text-white mb-0">{'-'}</h4>
            </div>
          </div>
          <div className="d-flex justify-content-between border-0 mt-3">
            <span className="text-white px-1">Rank : #{'-'}</span>
            <span className="text-white px-1">
              {'-'}
              {` `}Watchlist
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between border-0 p-2">
          <div className="footer-info font-w600 d-flex justify-content-between">
            <span className="text-success px-1">UP</span>
            <span className="text-black px-1 d-block">$ -</span>
          </div>
          <div className="footer-info font-w600 d-flex justify-content-between">
            <span className="text-danger px-1">DOWN</span>
            <span className="text-black px-1 d-block">$ -</span>
          </div>
          {/* <Link
            to="/portofolio"
            className="underline text-primary font-w500 footer-info"
          >
            More Details
          </Link> */}
        </div>
      </div>
    </div>
  );
}
