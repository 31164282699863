import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ApiConfig from '../../../config/ApiConfig';
import MultiSelect from './MultiSelect';
import axios from 'axios';
import { isBlank } from '../../CommonFunctions';
import CriculerLoader from './CircularLoader';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../../../context/Auth';

const optionList = [
  //   { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  //   { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'Max', label: 'Max' },
  //   { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'Min', label: 'Min' },
];

export default function EditProfileModal(props) {
  const auth = useContext(AuthContext);

  const [selectedNFT, setselectedNFT] = useState([]);
  const { userData, show, onHide } = props;
  const [projectLogoBuld, setProjectLogoBuld] = useState('');
  const [newProfilePic, setNewProfilePic] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [defaultSelectedOption, setDefaultSelectedOption] = useState([]);
  const [formValue, setFormValue] = useState({
    firstName: '',
    lastName: '',
    profilePic: '',
  });

  useEffect(() => {
    let name = userData.name && userData.name.split(' ');
    setselectedNFT(userData.NFTList);
    setProjectLogoBuld(userData.profilePic);
    let nftArr = [];
    userData.NFTList &&
      userData.NFTList.length > 0 &&
      userData.NFTList.forEach((data) => {
        nftArr.push({ value: data, label: data });
      });
    setDefaultSelectedOption(nftArr);
    setFormValue({
      firstName: name ? name[0] : '',
      lastName: name ? name[1] : '',
    });
  }, [userData]);

  const _onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const temp = { ...formValue, [name]: value };
    setFormValue(temp);
  };
  const accessToken = window.localStorage.getItem('accessToken');

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (err) {
      console.log('Error: ', err);
    };
  };

  const _onProfilePicChange = (e) => {
    console.log('eeee', e);
    const name = e.target.name;
    const value = URL.createObjectURL(e.target.files[0]);
    setProjectLogoBuld(value);
    getBase64(e.target.files[0], (result) => {
      console.log('result', result);
      const temp = { ...formValue, [name]: result };
      console.log('temp', temp);
      setFormValue(temp);
      setNewProfilePic(value);
    });
  };

  const notifyTopRight = (msg, type) => {
    toast[type](msg, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const editProfileHandler = async () => {
    try {
      setIsUpdating(true);
      const res = await axios.put(
        ApiConfig.editProfile,
        {
          name: formValue.firstName,
          profilePic: formValue.profilePic,
          // NFTList: selectedNFT,
        },
        { headers: { token: accessToken } }
      );
      setIsUpdating(false);
      if (res.data.response_code === 200) {
        notifyTopRight(res.data.response_message, 'success');
        auth.getProfileHandler();
      } else {
        notifyTopRight(res.data.response_message, 'warn');
      }
      console.log('resresresres', res);
    } catch (err) {
      setIsUpdating(false);
      console.log('ERROR', err);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <div className="row">
              <div className="col-sm-12">
                <lable>Username</lable>

                <div className="input-group input-group-lg mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    name="firstName"
                    value={formValue.firstName}
                    onChange={(e) => _onInputChange(e)}
                  />
                  {isSubmit && isBlank(formValue.firstName) && (
                    <p className="text-danger">Enter first name"</p>
                  )}
                </div>
              </div>
              {/* <div className="col-sm-6 mt-2 mt-sm-0">
            <input
              type="text"
              className="form-control"
              placeholder="Last name"
              name="lastName"
              value={formValue.lastName}
              onChange={(e) => _onInputChange(e)}
            />
            {isSubmit && isBlank(formValue.lastName) && (
              <p className="text-danger">Enter first name"</p>
            )}
          </div> */}
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <lable>Select Profile Image</lable>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                      }}
                    >
                      Upload
                    </span>
                  </div>
                  <div className="custom-file form-control-lg">
                    <input
                      // value={newProfilePic}
                      name="profilePic"
                      onChange={(e) => _onProfilePicChange(e)}
                      type="file"
                      className="custom-file-input"
                      accept="image/png,image/jpeg"
                    />
                    <label className="custom-file-label">Choose file</label>
                  </div>
                </div>
                {isSubmit && isBlank(projectLogoBuld) && (
                  <p className="text-danger">Select coin logo</p>
                )}
                {projectLogoBuld !== '' && (
                  <div className="form-group col-md-4 mt-2">
                    <img src={projectLogoBuld} alt="" width="100" />
                  </div>
                )}
              </div>
              {/* <div className="col-sm-12">
              <lable>Select Profile Image</lable>
              <input
                value={newProfilePic}
                name="profilePic"
                onChange={(e) => _onProfilePicChange(e)}
                type="file"
                className="form-control"
                accept="image/png,image/jpeg"
              />
              {isSubmit && isBlank(projectLogoBuld) && (
                <p className="text-danger">Select coin logo</p>
              )}
              {projectLogoBuld !== '' && (
                <div className="form-group col-md-4 mt-2">
                  <img src={projectLogoBuld} alt="" width="100" />
                </div>
              )}
            </div> */}
            </div>
          </div>

          {/* <div className="mt-3">
          <lable>Select NFT</lable>
          <MultiSelect
            optionList={optionList}
            defaultSelectedOption={defaultSelectedOption}
            setselectedNFTList={(data) => setselectedNFT(data)}
          />
        </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isUpdating}
            onClick={() => {
              editProfileHandler();
            }}
            style={{ display: 'flex' }}
          >
            Submit {isUpdating && <CriculerLoader />}
          </Button>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
