import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, Nav, Tab, Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Link, useLocation } from 'react-router-dom';
import btc from '../../../images/svg/btc.svg';
import desh from '../../../images/svg/dash.svg';
import eth from '../../../images/svg/eth.svg';
import ltc from '../../../images/svg/ltc.svg';
import loadable from '@loadable/component';
import 'bootstrap-daterangepicker/daterangepicker.css';
import pMinDelay from 'p-min-delay';
import {
  CoinmarketcapWidget,
  CoingeckoWidget,
  CardDetails,
} from './CardDetails';
import AddProjectModal from './AddProjectModal';
import axios from 'axios';
import ApiConfig from '../../../config/ApiConfig';
import moment from 'moment';
import { sortAddress } from '../../CommonFunctions/index';
import GraphComponent from '../CustomComponent/GraphComponent';
import EditProject from './EditProject';
import DeleteProjectPopUp from './DeleteProjectPopUp';
import { sortAmount } from '../../CommonFunctions/index';
import { AuthContext } from '../../../context/Auth';
import { ToastContainer, toast } from 'react-toastify';
import {
  isBlank,
  isUrlValid,
  isUrlValidTelegram,
} from '../../CommonFunctions/';
const MarketOverview = loadable(() =>
  pMinDelay(import('../kripton/CoinDetails/MarketOverview'), 1000)
);

const MarketOverviewDark = loadable(() =>
  pMinDelay(import('../kripton/CoinDetails/MarketOverviewDark'), 1000)
);

export default function InsightKIP() {
  const auth = useContext(AuthContext);

  const location = useLocation();
  const [modalShow, setModalShow] = React.useState(false);
  const [allProjectList, setallProjectList] = useState();
  const accessToken = window.localStorage.getItem('accessToken');
  const [selectedCoin, setSelectedCoin] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [coinOtherDetails, setCoinOtherDetails] = useState();
  const [coinmarketcapWidgetID, setcoinmarketcapWidgetID] = useState();
  const [isLoadingCoinMark, setIsLoadingCoinMark] = useState(false);
  const [coinGekoWidgeID, setcoinGekoWidgeID] = useState();
  const [isLoadingCoinGeko, setIsLoadingCoinGeko] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [oneDayPerChange, setOneDayPerChange] = useState();
  const [token24change, setToken24change] = useState();
  const [marketCapValue, setMarketCapValue] = useState();
  const [marketCapDecimals, setMarketCapDecimals] = useState();
  const [isAddProject, setIsAddProject] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').unix()
  );
  const [endDate, setEndDate] = useState(moment().unix());
  const [coinPrice, setCoinPrice] = useState();
  // useEffect(() => {
  //   if (location.state && location.state.coinName !== '') {
  //     setSelectedCoin(location.state);
  //   }
  // }, [location]);
  const projectListHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.listProject, {
        headers: {
          token: accessToken,
        },
      });
      setIsLoading(false);
      if (res.data.response_code === 200) {
        if (!location.state) {
          setSelectedCoin(res.data.result[0]);
          searchProjectHandler(res.data.result[0].coinShortName);
        } else {
          for (let i = 0; i < res.data.result.length; i++) {
            if (
              Object.values(res.data.result[i]).indexOf(
                location.state.contractAddress
              ) > -1
            ) {
              setSelectedCoin(location.state);
              searchProjectHandler(location.state.coinShortName);
              break;
            }
          }
        }
        setallProjectList(res.data.result);
      } else {
        setallProjectList([]);
      }
    } catch (err) {
      setallProjectList([]);
      console.log('ERROR', err);
    }
  };

  useEffect(() => {
    projectListHandler();
  }, [auth.userData.walletAddress]);

  useEffect(() => {
    if (
      selectedCoin &&
      selectedCoin.coinShortName &&
      selectedCoin.coinShortName.length > 1
    ) {
      setcoinmarketcapWidgetID();
      setcoinGekoWidgeID();
      getCoinDetails(selectedCoin.coinShortName);
      getCoingeckoID(selectedCoin.coinShortName);
      searchProjectHandler(selectedCoin.coinShortName);
      getcoinPriceHandler(selectedCoin.contractAddress);
      realTimePriceHandler(selectedCoin.contractAddress);
      getDayChnage(selectedCoin.contractAddress);
      getMarketCapValueHandler(selectedCoin.contractAddress);
    }
  }, [selectedCoin]);

  const getMarketCapValueHandler = async (baseCurrency) => {
    // []
    try {
      const res = await axios.get(
        `https://api.bscscan.com/api?module=stats&action=tokensupply&contractaddress=${baseCurrency}&apikey=YASYED2JP9PGEI735CH7FK4IHRP13FXAI6`
      );
      if (res.status === 200) {
        setMarketCapValue(res.data.result);
      } else {
        setMarketCapValue(0);
      }
    } catch (err) {
      setMarketCapValue(0);
      console.log('ERROR', err);
    }
  };

  const getcoinPriceHandler = async (baseCurrency) => {
    try {
      // const res = await axios.get(ApiConfig.tokenPrice, {
      //   params: {
      //     baseCurrency: baseCurrency,
      //   },
      // });
      const result1 = await axios.post('https://graphql.bitquery.io', {
        json: true,
        query: `{
                              ethereum(network: bsc) {
                                dexTrades(
                                  options: {desc: ["block.height", "transaction.index"], limit: 1}
                                  baseCurrency: {is: "${baseCurrency}"}
                                  quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"}
                                ) {
                                  block {
                                    height
                                    timestamp {
                                      time(format: "%Y-%m-%d %H:%M:%S")
                                    }
                                  }
                                  transaction {
                                    index
                                  }
                                  baseCurrency {
                                    symbol
                                    decimals
                                  }
                                  quoteCurrency {
                                    symbol
                                  }
                                  quotePrice
                                }
                              }
                            }
                            `,
      });
      const result2 = await axios.post('https://graphql.bitquery.io', {
        json: true,
        query: `{
                      ethereum(network: bsc) {
                        dexTrades(
                          options: {desc: ["block.height", "transaction.index"], limit: 1}
                          baseCurrency: {is: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"}
                          quoteCurrency: {is: "0x55d398326f99059ff775485246999027b3197955"}
                        ) {
                          block {
                            height
                            timestamp {
                              time(format: "%Y-%m-%d %H:%M:%S")
                            }
                          }
                          transaction {
                            index
                          }
                          baseCurrency {
                            symbol
                          }
                          quoteCurrency {
                            symbol                            
                          }
                          quotePrice
                        }
                      }
                    }
                    
                            `,
      });

      if (result1.status === 200 && result2.status === 200) {
        let TokenPrice =
          result1.data.data.ethereum.dexTrades[0].quotePrice *
          result2.data.data.ethereum.dexTrades[0].quotePrice;

        let PriceInBNB = result1.data.data.ethereum.dexTrades[0].quotePrice;
        localStorage.setItem(
          'BNBPriceInUSD',
          result2.data.data.ethereum.dexTrades[0].quotePrice
        );

        setCoinPrice({
          TokenPrice: TokenPrice,
          PriceInBNB: PriceInBNB,
        });
        setMarketCapDecimals(
          result1.data.data.ethereum.dexTrades[0].baseCurrency.decimals
        );
      } else {
        setMarketCapDecimals(0);
        setCoinPrice({
          TokenPrice: 0,
          PriceInBNB: 0,
        });
      }
    } catch (err) {
      setMarketCapDecimals(0);

      setCoinPrice({
        TokenPrice: 0,
        PriceInBNB: 0,
      });
      console.log('ERROR', err);
    }
  };

  const getDayChnage = async (baseCurrency) => {
    try {
      var result = await axios({
        method: 'post',
        url: `https://graphql.bitquery.io`,
        data: {
          query: `



          {
            ethereum(network: bsc) {
              dexTrades(
                options: {limit: 100, asc: "timeInterval.day"}
               time: {since: "${moment
                 .utc()
                 .subtract(1, 'days')
                 .format('YYYY-MM-DDThh:mm:ss')}"}
                baseCurrency: {is: "${baseCurrency}"}
                quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"}
                exchangeAddress: {in: ["0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73", "0xBCfCcbde45cE874adCB698cC183deBcF17952812"]}
              ) {
                timeInterval {
                  day(count: 1, format: "%Y-%m-%dT%H:%M:%SZ")
                }
                baseCurrency {
                  symbol
                  address
                }
                baseAmount
                quoteCurrency {
                  symbol
                  address
                }
                quotePrice
                tradeAmount(in: USDT)
              }
            }
          }`,
        },
      });
      if (
        result.status === 200 &&
        result.data.data.ethereum.dexTrades &&
        result.data.data.ethereum.dexTrades.length === 2
      ) {
        setToken24change(
          result.data.data.ethereum.dexTrades[0].tradeAmount +
            result.data.data.ethereum.dexTrades[1].tradeAmount
        );
      } else {
        setToken24change(0);
      }
    } catch (err) {
      setToken24change(0);
      console.log('ERROR', err);
    }
  };

  const realTimePriceHandler = async (baseCurrency) => {
    try {
      // const res = await axios.post(`${ApiConfig.realTimePrice}`, {
      //   since: moment().subtract(1, 'days'),
      //   till: new Date(),
      //   quoteCurrency: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      //   order: 'asc',
      //   baseCurrency: baseCurrency,
      //   minTrade: '10',
      //   window: '60',
      // });

      var response1 = await axios({
        method: 'post',
        url: `https://graphql.bitquery.io`,
        data: {
          query: `
      {
        ethereum(network: bsc) {
            dexTrades(
                options: {${'asc'}: "timeInterval.minute"}
                time: {since: "${moment
                  .utc()
                  .subtract(1, 'days')
                  .format('YYYY-MM-DDThh:mm:ss')}", till: "${moment
            .utc()
            .format('YYYY-MM-DDThh:mm:ss')}"}
                exchangeAddress: {in: ["0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73","0xBCfCcbde45cE874adCB698cC183deBcF17952812"]}
                baseCurrency: {is: "${baseCurrency}"}
                quoteCurrency: {is: "${'0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'}"}
                tradeAmountUsd: {gt: ${'10'}}
            ) {
                timeInterval {
                    minute(count: ${'60'}, format: "%Y-%m-%dT%H:%M:%SZ")
                }
                baseCurrency {
                    symbol
                    address
                }
                quoteCurrency {
                    symbol
                    address
                }
    
                tradeAmount(in: USD)
                trades: count
                quotePrice
                maximum_price: quotePrice(calculate: maximum)
                minimum_price: quotePrice(calculate: minimum)
                open_price: minimum(of: block, get: quote_price)
                close_price: maximum(of: block, get: quote_price)
            }
        }
    }
     `,
        },
      });
      if (response1.status === 200) {
        let coinDetails = response1.data.data.ethereum;
        let oneDayChange = 0;
        if (coinDetails.dexTrades && coinDetails.dexTrades.length > 0) {
          oneDayChange =
            [
              (parseFloat(
                coinDetails.dexTrades[coinDetails.dexTrades.length - 1]
                  .close_price
              ) -
                parseFloat(coinDetails.dexTrades[0].open_price)) /
                parseFloat(coinDetails.dexTrades[0].open_price),
            ] * 100;
          // [
          //   (parseFloat(coinDetails.data.dexTrades[0].open_price) -
          //     parseFloat(
          //       coinDetails.data.dexTrades[
          //         coinDetails.data.dexTrades.length - 1
          //       ].close_price
          //     )) /
          //     parseFloat(
          //       coinDetails.data.dexTrades[
          //         coinDetails.data.dexTrades.length - 1
          //       ].close_price
          //     ),
          // ] * 100;

          setOneDayPerChange(oneDayChange);
        } else {
          setOneDayPerChange(0);
        }
      } else {
        setOneDayPerChange(0);
      }
    } catch (err) {
      setOneDayPerChange(0);
      console.log('ERROR', err);
    }
  };

  const searchProjectHandler = async (coinShortName) => {
    try {
      const res = await axios.get(ApiConfig.searchProject, {
        params: {
          search: coinShortName,
        },
        headers: {
          token: accessToken,
        },
      });
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  const getCoingeckoID = async (coinShortName) => {
    setIsLoadingCoinGeko(true);
    try {
      const res = await axios.get(
        'https://api.coingecko.com/api/v3/coins/list'
      );
      setIsLoadingCoinGeko(false);

      if (res.status === 200) {
        // console.log('getCoingeckoID', res);
        const data = res.data;
        let selectedCoinData = data.filter(
          (data) => data.symbol === coinShortName.toLowerCase()
        );
        if (selectedCoinData && selectedCoinData.length > 0) {
          setcoinGekoWidgeID(selectedCoinData[0].id);
        } else {
          setcoinGekoWidgeID();
        }
      }
    } catch (err) {
      setIsLoadingCoinGeko(false);
      setcoinGekoWidgeID();
      console.log('ERROR', err);
    }
  };

  const getCoinDetails = async (coinShortName) => {
    setIsLoadingCoinMark(true);
    try {
      const res = await axios.get(
        `${ApiConfig.getMarketCapSymbol}/${coinShortName}`
      );
      setIsLoadingCoinMark(false);

      if (res.data.responseCode === 200) {
        let coinDetails =
          res.data.responseResult.data[
            Object.keys(res.data.responseResult.data)[0]
          ].quote.USD;
        setcoinmarketcapWidgetID(
          res.data.responseResult.data[
            Object.keys(res.data.responseResult.data)[0]
          ].id
        );
        setCoinOtherDetails({
          price: coinDetails.price,
          percent_change_24h: coinDetails.percent_change_24h,
          volume_24h: coinDetails.volume_24h,
          market_cap: coinDetails.market_cap,
        });
      } else {
        setCoinOtherDetails();
        setcoinmarketcapWidgetID();
      }
    } catch (err) {
      setCoinOtherDetails();
      setcoinmarketcapWidgetID();
      setIsLoadingCoinMark(false);
      console.log('ERROR', err);
    }
    // return true;
  };

  const handleEvent = (event, picker) => {};
  const handleCallback = (start, end, label) => {
    setStartDate(moment(start).unix());
    setEndDate(moment(end).unix());
  };

  const addProjectHandler = async () => {
    setIsSubmit(true);
    try {
      if (
        !isBlank(selectedCoin.contractAddress) &&
        // !isBlank(formValue.description) &&
        // !isBlank(formValue.coinImage) &&
        (selectedCoin.community === '' ||
          (selectedCoin.community !== '' &&
            isUrlValidTelegram(selectedCoin.community))) &&
        (selectedCoin.website === '' ||
          (selectedCoin.website !== '' && isUrlValid(selectedCoin.website)))
      ) {
        setIsUpdating(true);
        const res = await axios.post(ApiConfig.addProject, selectedCoin, {
          headers: {
            token: accessToken,
          },
        });
        console.log('res', res.data.response_code);
        setIsUpdating(false);
        if (res.data.response_code === 200) {
          projectListHandler();
          notifyTopRight(res.data.response_message, 'success');

          setIsSubmit(false);
          setIsAddProject(false);
        } else {
          notifyTopRight(res.data.response_message, 'warn');
        }
      }
    } catch (err) {
      notifyTopRight('Failed', 'warn');
      console.log('ERROR', err);
      setIsUpdating(false);
    }
  };

  const notifyTopRight = (msg, type) => {
    toast[type](msg, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <>
      {' '}
      <ToastContainer />
      {modalShow && (
        <AddProjectModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          projectListHandler={projectListHandler}
          setSelectedCoinHandler={(data) => setSelectedCoin(data)}
        />
      )}
      {isEditOpen && (
        <EditProject
          show={isEditOpen}
          onHide={() => setIsEditOpen(false)}
          projectListHandler={projectListHandler}
          coinDetails={selectedCoin}
        />
      )}
      {isDeleteOpen && (
        <DeleteProjectPopUp
          show={isDeleteOpen}
          onHide={() => setIsDeleteOpen(false)}
          projectListHandler={projectListHandler}
          id={selectedCoin && selectedCoin._id}
        />
      )}
      <div className="d-flex" style={{ justifyContent: 'space-between' }}>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="form-control style-1  mb-3 "
            style={{
              width: '300px',
              textAlign: 'left',
              borderRadius: '2rem',
            }}
          >
            {allProjectList &&
              allProjectList.length > 0 &&
              selectedCoin &&
              selectedCoin.coinName}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {allProjectList &&
              allProjectList.length > 0 &&
              allProjectList.map((data, i) => {
                return (
                  <Dropdown.Item
                    style={{
                      width: '300px ',
                    }}
                    key={i}
                    onClick={() => setSelectedCoin(data)}
                  >
                    {data.coinName}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
        <div className="text-right mb-4">
          <button
            className="btn btn-primary btn-rounded"
            onClick={() => {
              setModalShow(true);
              setIsAddProject(true);
            }}
            disabled={!auth.userLoggedIn}
          >
            Add Project
          </button>
        </div>
      </div>
      <>
        {isLoading ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}
          >
            <div class="loader"></div>
          </div>
        ) : (
          <>
            {allProjectList && allProjectList.length === 0 && !selectedCoin ? (
              <div className="row">
                <div className="col-xl-12 col-xxl-12">
                  <div className="card">
                    <div className="card-header pb-0 border-0">
                      <h4 className="mb-3 text-black fs-20">
                        {/* {auth.userLoggedIn
                          ? 'Please add a project'
                          : 'Connect your wallet'} */}
                        Please add a project
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-xl-3 col-xxl-3">
                    <div className="card">
                      <div className="card-header pb-0 border-0">
                        <h4 className="mb-0 text-black fs-20">Intro</h4>
                        <Dropdown className="dropdown ml-auto">
                          <Dropdown.Toggle
                            variant=""
                            className="i-false p-0 btn-link"
                            data-toggle="dropdown"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <circle fill="#000000" cx={12} cy={5} r={2} />
                                <circle fill="#000000" cx={12} cy={12} r={2} />
                                <circle fill="#000000" cx={12} cy={19} r={2} />
                              </g>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="dropdown-menu dropdown-menu-right"
                            alignRight={true}
                          >
                            <Dropdown.Item
                              onClick={() => setIsEditOpen(true)}
                              className="dropdown-item"
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setIsDeleteOpen(true)}
                              className="dropdown-item"
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="card-body">
                        <div className="text-center mb-3">
                          <img
                            className="mb-3"
                            style={{
                              height: 160,
                              width: 160,
                              borderRadius: '50%',
                            }}
                            src={
                              selectedCoin && selectedCoin.coinImage
                                ? selectedCoin.coinImage
                                : eth
                            }
                            alt=""
                          />
                          <h2 className="fs-24 font-w600 text-black mb-0">
                            {selectedCoin && selectedCoin.coinName}
                          </h2>
                          <p className="fs-14 font-w600 text-black">
                            {selectedCoin && selectedCoin.coinShortName}
                          </p>
                          <span className="text-primary fs-14">
                            1 {selectedCoin && selectedCoin.coinShortName} ={' '}
                            {coinPrice &&
                            coinPrice.TokenPrice &&
                            parseFloat(coinPrice.TokenPrice).toFixed(6) < 1e-8
                              ? sortAmount(
                                  parseFloat(coinPrice.TokenPrice).toString()
                                )
                              : coinPrice &&
                                coinPrice.TokenPrice &&
                                parseFloat(coinPrice.TokenPrice).toFixed(6)}
                            USD
                          </span>
                        </div>
                        <p className="fs-14">
                          {selectedCoin && selectedCoin.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-xxl-9">
                    <div className="card">
                      <div className="row">
                        <div className="col-xl-5 col-xxl-5">
                          <div className="card-header pb-0 border-0">
                            <h4 className="mb-0 text-black fs-20">Details</h4>
                          </div>
                          <div className="card-body">
                            <div className="mb-4">
                              <p className="mb-2">Contract Address</p>
                              <h4 className="text-black">
                                {selectedCoin &&
                                  sortAddress(selectedCoin.contractAddress)}
                              </h4>
                            </div>
                            <div className="mb-4">
                              <p className="mb-2">Token Type</p>
                              <h4 className="text-black">
                                {selectedCoin && selectedCoin.tokenType}
                              </h4>
                            </div>
                            <div className="mb-4">
                              <p className="mb-2">Launch Date</p>
                              <h4 className="text-black">
                                {selectedCoin &&
                                  moment(selectedCoin.launchDate).format(
                                    'DD MMM YYYY'
                                  )}
                              </h4>
                            </div>
                            {selectedCoin && selectedCoin.community && (
                              <div className="mb-4">
                                <p className="mb-2">Community</p>
                                <h4
                                  className="text-blue"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    window.open(selectedCoin.community)
                                  }
                                >
                                  {selectedCoin && selectedCoin.community}
                                </h4>
                              </div>
                            )}
                            {selectedCoin && selectedCoin.website && (
                              <div className="mb-4">
                                <p className="mb-2">Website</p>
                                <h4
                                  className="text-blue"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    window.open(selectedCoin.website)
                                  }
                                >
                                  {selectedCoin && selectedCoin.website}
                                </h4>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-7 col-xxl-7">
                          {/* {selectedCoin && selectedCoin.isMarketCap && ( */}
                          <div className="card-body">
                            {coinmarketcapWidgetID && !isLoadingCoinMark ? (
                              <CoinmarketcapWidget
                                bgColor={'#886e9f'}
                                id={coinmarketcapWidgetID}
                              />
                            ) : (
                              <CardDetails bgColor={'#886e9f'} />
                            )}

                            {/* )} */}
                            {/* {selectedCoin && selectedCoin.isCoinGecko && ( */}
                            {/* <br /> */}
                            <div className="mt-2">
                              {coinGekoWidgeID && !isLoadingCoinGeko ? (
                                <CoingeckoWidget
                                  bgColor={'#b3b465'}
                                  id={coinGekoWidgeID}
                                />
                              ) : (
                                <CardDetails bgColor={'#b3b465'} />
                              )}
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                      <div className="card-header pb-0 d-block d-sm-flex flex-wrap border-0 align-items-center">
                        <div className="mr-auto mb-1">
                          <h4 className="fs-20 text-black">Coin Chart</h4>
                        </div>

                        <div className="input-group detault-daterange mr-3  mb-3 text-right">
                          <DateRangePicker
                            onEvent={handleEvent}
                            onCallback={handleCallback}
                            initialSettings={{
                              startDate: moment().subtract(1, 'months'),
                              endDate: new Date(),
                            }}
                          >
                            <input type="text" className="form-control" />
                          </DateRangePicker>
                        </div>
                      </div>
                      <div className="card-body pb-0 pt-sm-3 pt-0">
                        <div className="row sp20 mb-4 align-items-center">
                          <div className="col-lg-8 col-xxl-12 d-flex flex-wrap justify-content-between align-items-center">
                            <div className="px-2 info-group">
                              <p className="fs-18 mb-4">Price</p>
                              <h2 className="fs-20 font-w600 text-black">
                                $
                                {coinPrice &&
                                coinPrice.TokenPrice &&
                                parseFloat(coinPrice.TokenPrice).toFixed(6) <
                                  1e-8
                                  ? sortAmount(
                                      parseFloat(
                                        coinPrice.TokenPrice
                                      ).toString()
                                    )
                                  : coinPrice &&
                                    coinPrice.TokenPrice &&
                                    parseFloat(coinPrice.TokenPrice).toFixed(6)}
                                {/* {coinPrice &&
                            coinPrice.TokenPrice &&
                            parseFloat(coinPrice.TokenPrice).toFixed(6)} */}
                              </h2>
                            </div>
                            <div className="px-2 info-group">
                              <p className="fs-18 mb-4">Price BNB</p>
                              <h2 className="fs-20 font-w600 text-black">
                                B{' '}
                                {coinPrice &&
                                coinPrice.PriceInBNB &&
                                parseFloat(coinPrice.PriceInBNB).toFixed(6) <
                                  1e-8
                                  ? sortAmount(
                                      parseFloat(
                                        coinPrice.PriceInBNB
                                      ).toString()
                                    )
                                  : coinPrice &&
                                    coinPrice.PriceInBNB &&
                                    parseFloat(coinPrice.PriceInBNB).toFixed(6)}
                              </h2>
                            </div>
                            <div className="px-2 info-group">
                              <p className="fs-18 ">24h% change</p>
                              <h3
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  alignContent: 'center',
                                }}
                                className={`fs-20 font-w600 ${
                                  oneDayPerChange &&
                                  parseFloat(oneDayPerChange).toFixed(2) > 0
                                    ? 'text-success'
                                    : 'text-danger'
                                } `}
                              >
                                {oneDayPerChange &&
                                  Math.abs(
                                    parseFloat(oneDayPerChange).toFixed(2)
                                  )}
                                %
                                {oneDayPerChange &&
                                parseFloat(oneDayPerChange).toFixed(2) > 0 ? (
                                  <i
                                    class="fa fa-sort-asc"
                                    aria-hidden="true"
                                    style={{
                                      color: '#61C277',
                                      fontSize: 26,
                                      paddingTop: 11,
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-sort-desc"
                                    aria-hidden="true"
                                    style={{
                                      color: 'red',
                                      fontSize: 26,
                                      paddingBottom: 11,
                                    }}
                                  ></i>
                                )}
                              </h3>
                            </div>
                            <div className="px-2 info-group">
                              <p className="fs-18 mb-4">Volume (24h)</p>
                              <h3 className="fs-20 font-w600 text-black">
                                ${' '}
                                {token24change &&
                                parseFloat(token24change).toFixed(6) < 1e-8
                                  ? sortAmount(
                                      parseFloat(token24change).toString()
                                    )
                                  : token24change &&
                                    token24change &&
                                    parseFloat(token24change).toFixed(2)}
                                {/* {token24change &&
                            parseFloat(token24change).toFixed(2)} */}
                              </h3>
                            </div>
                            <div className="px-2 info-group">
                              <p className="fs-18 mb-4">Market Cap</p>
                              <h3 className="fs-20 font-w600 text-black">
                                $
                                {marketCapValue &&
                                  coinPrice &&
                                  coinPrice.TokenPrice &&
                                  marketCapDecimals &&
                                  parseFloat(
                                    marketCapValue *
                                      Math.pow(10, -marketCapDecimals) *
                                      coinPrice.TokenPrice
                                  ).toFixed(2)}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="row pl-2 pr-2">
                          <div
                            className="col-lg-12 col-xxl-12 "
                            style={{ height: 450 }}
                          >
                            {selectedCoin && selectedCoin.contractAddress ? (
                              <GraphComponent
                                quoteCurrency={selectedCoin.contractAddress}
                                shotName={selectedCoin.coinShortName}
                                startDate={startDate}
                                endDate={endDate}
                              />
                            ) : (
                              <GraphComponent
                                quoteCurrency={
                                  '0x62D7aA57125169101626a993fa46685313A774Ce'
                                }
                                shotName={'ETH'}
                                startDate={startDate}
                                endDate={endDate}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row pl-2 pr-2 mt-2 mb-3">
                          <div className="col-xl-12 col-xxl-12 text-right">
                            {isAddProject && (
                              <Button
                                onClick={addProjectHandler}
                                disabled={isUpdating}
                              >
                                Add Project
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    </>
  );
}
