import React, { useContext, useEffect, useState } from 'react';
import profile from '../../../images/unnamed.png';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CoinDetailsCard from '../../components/CustomComponent/CoinDetailsCard';
import LatestNews from '../../components/CustomComponent/LatestNews';
import EditProfileModal from '../../components/CustomComponent/EditProfileModal';
import { AuthContext } from '../../../context/Auth';
import moment from 'moment';
import axios from 'axios';
import ApiConfig, { socketURL } from '../../../config/ApiConfig';
const Home = () => {
  const auth = useContext(AuthContext);
  const [modalShow, setModalShow] = React.useState(false);
  const accessToken = window.localStorage.getItem('accessToken');
  const [hottestScannedProjectList, setHottestScannedProjectList] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [localStorageLoading, setLocalStorageLoading] = useState(true);
  const [coinOtherDetails, setCoinOtherDetails] = useState([]);
  const [coinBuySellPrice, setCoinBuySellPrice] = useState([]);
  const [coinPrice, setCoinPrice] = useState([]);
  const [isScore, setIsScore] = useState(false);
  const [moonRetrieverList, setMoonRetrieverList] = useState();

  useEffect(() => {
    if (isScore) {
      if (
        JSON.parse(localStorage.getItem('coinPriceScore')) &&
        JSON.parse(localStorage.getItem('coinOtherDetailsScore')) &&
        JSON.parse(localStorage.getItem('coinBuySellPriceScore')) &&
        JSON.parse(localStorage.getItem('hottestScannedProjectListScore'))
      ) {
        setCoinPrice(JSON.parse(localStorage.getItem('coinPriceScore')));
        setCoinOtherDetails(
          JSON.parse(localStorage.getItem('coinOtherDetailsScore'))
        );
        setCoinBuySellPrice(
          JSON.parse(localStorage.getItem('coinBuySellPriceScore'))
        );
        setHottestScannedProjectList(
          JSON.parse(localStorage.getItem('hottestScannedProjectListScore'))
        );
        setLocalStorageLoading(false);
      }
    } else {
      if (
        JSON.parse(localStorage.getItem('coinPrice')) &&
        JSON.parse(localStorage.getItem('coinOtherDetails')) &&
        JSON.parse(localStorage.getItem('coinBuySellPrice')) &&
        JSON.parse(localStorage.getItem('hottestScannedProjectList'))
      ) {
        setCoinPrice(JSON.parse(localStorage.getItem('coinPrice')));
        setCoinOtherDetails(
          JSON.parse(localStorage.getItem('coinOtherDetails'))
        );
        setCoinBuySellPrice(
          JSON.parse(localStorage.getItem('coinBuySellPrice'))
        );
        setHottestScannedProjectList(
          JSON.parse(localStorage.getItem('hottestScannedProjectList'))
        );
        setLocalStorageLoading(false);
      }
    }
    // localStorage.setItem('coinPrice', JSON.stringify(coinPriceArr));
    //     localStorage.setItem('coinOtherDetails', JSON.stringify(arrData));
    //     localStorage.setItem('coinBuySellPrice', JSON.stringify(buySellArr));
    //     localStorage.setItem(
    //       'hottestScannedProjectList',
    //       JSON.stringify(res.data.result)
    //     );
  }, [isScore]);

  const hottestScannedProjectHandler = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(ApiConfig.hottestScannedProject, {
        headers: {
          token: accessToken,
        },
      });

      if (res.data.response_code === 200) {
        let arrData = [];
        let buySellArr = [];
        let coinPriceArr = [];
        for (let i = 0; i < res.data.result.length; i++) {
          let coinDetails = {};
          let buySellData = [];

          let coinPriceData = {};
          if (
            res.data.result[i].coinShortName &&
            res.data.result[i].coinShortName.length > 1
          ) {
            coinDetails = await getCoinDetails(
              res.data.result[i].coinShortName
            );
          } else {
            coinDetails = await getCoinDetails('BTC');
          }
          if (
            res.data.result[i].contractAddress &&
            res.data.result[i].contractAddress.length > 5
          ) {
            buySellData = await getBuySellPrice(
              res.data.result[i].contractAddress
            );
            coinPriceData = await getcoinPriceHandler(
              res.data.result[i].contractAddress
            );
          } else {
            buySellData = await getBuySellPrice(
              '0x1997830b5beb723f5089bb8fc38766d419a0444d'
            );
          }

          let coinShortName = res.data.result[i].coinShortName;
          coinPriceArr.push({
            [coinShortName]: coinPriceData,
          });
          arrData.push({
            [coinShortName]: coinDetails,
          });
          buySellArr.push({
            [coinShortName]: buySellData,
          });
          // setCoinPrice(coinPriceArr);
          // setCoinOtherDetails(arrData);
          // setCoinBuySellPrice(buySellArr);
          // setHottestScannedProjectList(res.data.result.slice(0, i + 1));
          // setIsLoading(false);
        }

        setCoinPrice(coinPriceArr);
        setCoinOtherDetails(arrData);
        setCoinBuySellPrice(buySellArr);
        setHottestScannedProjectList(res.data.result);
        setIsLoading(false);

        localStorage.setItem('coinPrice', JSON.stringify(coinPriceArr));
        localStorage.setItem('coinOtherDetails', JSON.stringify(arrData));
        localStorage.setItem('coinBuySellPrice', JSON.stringify(buySellArr));
        localStorage.setItem(
          'hottestScannedProjectList',
          JSON.stringify(res.data.result)
        );
      } else {
        setHottestScannedProjectList([]);
      }
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  const hottestScannedProjectByScoreHandler = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(ApiConfig.hottestScannedProjectByScore, {
        headers: {
          token: accessToken,
        },
      });

      if (res.data.response_code === 200) {
        let arrData = [];
        let buySellArr = [];
        let coinPriceArr = [];
        for (let i = 0; i < res.data.result.length; i++) {
          let coinDetails = {};
          let buySellData = [];

          let coinPriceData = {};
          if (
            res.data.result[i].coinShortName &&
            res.data.result[i].coinShortName.length > 1
          ) {
            coinDetails = await getCoinDetails(
              res.data.result[i].coinShortName
            );
          } else {
            coinDetails = await getCoinDetails('BTC');
          }
          if (
            res.data.result[i].contractAddress &&
            res.data.result[i].contractAddress.length > 5
          ) {
            buySellData = await getBuySellPrice(
              res.data.result[i].contractAddress
            );
            coinPriceData = await getcoinPriceHandler(
              res.data.result[i].contractAddress
            );
          } else {
            buySellData = await getBuySellPrice(
              '0x1997830b5beb723f5089bb8fc38766d419a0444d'
            );
          }

          let coinShortName = res.data.result[i].coinShortName;
          coinPriceArr.push({
            [coinShortName]: coinPriceData,
          });
          arrData.push({
            [coinShortName]: coinDetails,
          });
          buySellArr.push({
            [coinShortName]: buySellData,
          });
          // setCoinPrice(coinPriceArr);
          // setCoinOtherDetails(arrData);
          // setCoinBuySellPrice(buySellArr);
          // setHottestScannedProjectList(res.data.result.slice(0, i + 1));
          // setIsLoading(false);
        }

        setCoinPrice(coinPriceArr);
        setCoinOtherDetails(arrData);
        setCoinBuySellPrice(buySellArr);
        setHottestScannedProjectList(res.data.result);
        setIsLoading(false);

        localStorage.setItem('coinPriceScore', JSON.stringify(coinPriceArr));
        localStorage.setItem('coinOtherDetailsScore', JSON.stringify(arrData));
        localStorage.setItem(
          'coinBuySellPriceScore',
          JSON.stringify(buySellArr)
        );
        localStorage.setItem(
          'hottestScannedProjectListScore',
          JSON.stringify(res.data.result)
        );
      } else {
        setHottestScannedProjectList([]);
      }
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  const getcoinPriceHandler = async (baseCurrency) => {
    try {
      // const res = await axios.get(ApiConfig.tokenPrice, {
      //   params: {
      //     baseCurrency: baseCurrency,
      //   },
      // });
      const result1 = await axios.post('https://graphql.bitquery.io', {
        json: true,
        query: `{
                              ethereum(network: bsc) {
                                dexTrades(
                                  options: {desc: ["block.height", "transaction.index"], limit: 1}
                                  baseCurrency: {is: "${baseCurrency}"}
                                  quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"}
                                ) {
                                  block {
                                    height
                                    timestamp {
                                      time(format: "%Y-%m-%d %H:%M:%S")
                                    }
                                  }
                                  transaction {
                                    index
                                  }
                                  baseCurrency {
                                    symbol
                                  }
                                  quoteCurrency {
                                    symbol
                                  }
                                  quotePrice
                                }
                              }
                            }
                            `,
      });
      const result2 = await axios.post('https://graphql.bitquery.io', {
        json: true,
        query: `{
                      ethereum(network: bsc) {
                        dexTrades(
                          options: {desc: ["block.height", "transaction.index"], limit: 1}
                          baseCurrency: {is: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"}
                          quoteCurrency: {is: "0x55d398326f99059ff775485246999027b3197955"}
                        ) {
                          block {
                            height
                            timestamp {
                              time(format: "%Y-%m-%d %H:%M:%S")
                            }
                          }
                          transaction {
                            index
                          }
                          baseCurrency {
                            symbol
                          }
                          quoteCurrency {
                            symbol
                          }
                          quotePrice
                        }
                      }
                    }
                    
                            `,
      });

      if (result1.status === 200 && result2.status === 200) {
        let TokenPrice =
          result1.data.data.ethereum.dexTrades[0].quotePrice *
          result2.data.data.ethereum.dexTrades[0].quotePrice;

        let PriceInBNB = result1.data.data.ethereum.dexTrades[0].quotePrice;

        return {
          coinPrice: { TokenPrice: TokenPrice, PriceInBNB: PriceInBNB },
        };
        // setCoinPrice({
        //   TokenPrice: TokenPrice,
        //   PriceInBNB: PriceInBNB,
        // });
      } else {
        return {
          coinPrice: { TokenPrice: 0 },
        };
      }
    } catch (err) {
      console.log('ERROR', err);
      return {
        coinPrice: { TokenPrice: 0 },
      };
    }
  };

  const getCoinDetails = async (coinShortName) => {
    const res = await axios.get(
      `${ApiConfig.getMarketCapSymbol}/${coinShortName}`
    );

    if (res.data.responseCode === 200) {
      let coinDetails =
        res.data.responseResult.data[
          Object.keys(res.data.responseResult.data)[0]
        ].quote.USD;
      return {
        coinPrice: coinDetails.price,
        percentChange7d: coinDetails.percent_change_7d,
      };
    } else {
      return { coinPrice: 0, percentChange7d: 0 };
    }
    // return true;
  };

  useEffect(() => {
    if (isScore) {
      hottestScannedProjectByScoreHandler();
    } else {
      hottestScannedProjectHandler();
    }
  }, [isScore]);

  const getBuySellPrice = async (baseCurrency) => {
    const res = '';
    // const res = await axios.post(`${ApiConfig.realTimePrice}`, {
    //   since: moment().subtract(6, 'days'),
    //   till: new Date(),
    //   quoteCurrency: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    //   order: 'asc',
    //   baseCurrency: baseCurrency,
    //   minTrade: '10',
    //   window: '60',
    // });
    try {
      var response1 = await axios({
        method: 'post',
        url: `https://graphql.bitquery.io`,
        data: {
          query: `
        {
          ethereum(network: bsc) {
              dexTrades(
                  options: {${'asc'}: "timeInterval.minute"}
                  date: {since: "${moment()
                    .subtract(6, 'days')
                    .format('YYYY-MM-DD')}", till: "${moment().format(
            'YYYY-MM-DD'
          )}"}
                  exchangeAddress: {in: ["0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73","0xBCfCcbde45cE874adCB698cC183deBcF17952812"]}
                  baseCurrency: {is: "${baseCurrency}"}
                  quoteCurrency: {is: "${'0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'}"}
                  tradeAmountUsd: {gt: ${'10'}}
              ) {
                  timeInterval {
                      minute(count: ${'60'}, format: "%Y-%m-%dT%H:%M:%SZ")
                  }
                  baseCurrency {
                      symbol
                      address
                  }
                  quoteCurrency {
                      symbol
                      address
                  }
      
                  tradeAmount(in: USD)
                  trades: count
                  quotePrice
                  maximum_price: quotePrice(calculate: maximum)
                  minimum_price: quotePrice(calculate: minimum)
                  open_price: minimum(of: block, get: quote_price)
                  close_price: maximum(of: block, get: quote_price)
              }
          }
      }
       `,
        },
      });

      var response2 = await axios({
        method: 'post',
        url: `https://graphql.bitquery.io`,
        data: {
          query: `
          {
            ethereum(network: bsc) {
              dexTrades(
                baseCurrency: {is: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"}
                quoteCurrency: {is: "0x55d398326f99059ff775485246999027b3197955"}
                options: {desc: ["block.height", "transaction.index"], limit: 1}
              ) {
                block {
                  height
                  timestamp {
                    time(format: "%Y-%m-%d %H:%M:%S")
                  }
                }
                transaction {
                  index
                }
                baseCurrency {
                  symbol
                }
                quoteCurrency {
                  symbol
                }
                quotePrice
              }
            }
          } `,
        },
      });

      if (response1.status === 200 && response2.status === 200) {
        let quotePrice = response2.data.data.ethereum.dexTrades[0].quotePrice;
        let coinDetails = response1.data.data.ethereum;

        let sevenDaysChnage = 0;
        if (coinDetails.dexTrades && coinDetails.dexTrades.length > 0) {
          sevenDaysChnage =
            [
              (parseFloat(coinDetails.dexTrades[0].open_price) -
                parseFloat(
                  coinDetails.dexTrades[coinDetails.dexTrades.length - 1]
                    .close_price
                )) /
                parseFloat(
                  coinDetails.dexTrades[coinDetails.dexTrades.length - 1]
                    .close_price
                ),
            ] * 100;
          return {
            buyPrice: parseFloat(
              coinDetails.dexTrades[coinDetails.dexTrades.length - 1]
                .maximum_price * quotePrice
            ),
            sellPrice: parseFloat(
              coinDetails.dexTrades[coinDetails.dexTrades.length - 1]
                .minimum_price * quotePrice
            ),
            sevenDaysChnage: sevenDaysChnage,
          };
        } else {
          return {
            buyPrice: 0,
            sellPrice: 0,
            sevenDaysChnage: sevenDaysChnage,
          };
        }
      } else {
        // getBuySellPrice(quoteCurrency);
        return { buyPrice: 0, sellPrice: 0, sevenDaysChnage: 0 };
      }
    } catch (err) {
      console.log('ERROR', err);
      return { buyPrice: 0, sellPrice: 0, sevenDaysChnage: 0 };
    }
  };

  const moonRetrieverListHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.moonRetrieverList, {
        headers: {
          token: accessToken,
        },
      });
      if (res.data.response_code === 200) {
        setMoonRetrieverList(res.data.result);
      } else {
        setMoonRetrieverList([]);
      }
    } catch (err) {
      setMoonRetrieverList([]);
      console.log('ERROR', err);
    }
  };

  useEffect(() => {
    moonRetrieverListHandler();
  }, []);

  return (
    <>
      <EditProfileModal
        userData={auth.userData}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="row">
        {auth.userLoggedIn && (
          <div className={`col-xl-3 col-xxl-4`}>
            <div className="card">
              <div className="card-header pb-0 border-0">
                <h5 className="mb-0 text-black fs-20">My Profile</h5>
              </div>
              <div className="card-body">
                <div className="text-center">
                  <img
                    src={
                      auth.userData.profilePic
                        ? auth.userData.profilePic
                        : profile
                    }
                    alt=""
                    className=""
                    style={{
                      height: 200,
                      width: 200,
                      borderRadius: '50%',
                    }}
                  />
                  <h4 className="fs-26 mt-3 font-w600 text-black">
                    {auth.userData.name}
                  </h4>
                  <p className="mb-0 text-black ">
                    Join on{' '}
                    {moment(auth.userData.createdAt).format('DD MMM YYYY')}
                  </p>
                  <p className="mb-0 text-black mt-3">
                    NFT :{' '}
                    {auth.userData.NFTList && auth.userData.NFTList.toString()}
                  </p>
                  <button
                    onClick={() => {
                      setModalShow(true);
                    }}
                    className="btn btn-rounded button-style btn-outline-primary mt-4"
                  >
                    <i className="fa fa-pencil scale5 mr-3" />
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={
            auth.userLoggedIn ? 'col-xl-9 col-xxl-8' : `col-xl-12 col-xxl-12`
          }
        >
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h5 className="mb-0 text-black fs-20">
                Hottest Scanned Projects
              </h5>
              <div className="custom-control custom-switch toggle-switch text-right mr-4 mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch11"
                  onClick={() => setIsScore(!isScore)}
                />

                <label
                  className="custom-control-label fs-14 pr-2"
                  htmlFor="customSwitch11"
                  style={{ marginRight: 60 }}
                >
                  N &#176; of Checks
                </label>
                <label
                  htmlFor="customSwitch11"
                  style={{ position: 'absolute', right: 0 }}
                >
                  Score
                </label>
              </div>
            </div>
            <div className="card-body">
              {/* {!auth.userLoggedIn && !isLoading && (
                <h5>Connect your wallet to display details</h5>
              )} */}
              {isLoading && localStorageLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div class="loader"></div>
                </div>
              ) : (
                <div className="row">
                  {hottestScannedProjectList &&
                    hottestScannedProjectList.length > 0 &&
                    hottestScannedProjectList.map((data, i) => {
                      return (
                        <CoinDetailsCard
                          key={i}
                          data={data}
                          coinPrice={coinPrice[i][data.coinShortName]}
                          coinOtherDetails={
                            coinOtherDetails[i][data.coinShortName]
                          }
                          coinBuySellPrice={
                            coinBuySellPrice[i][data.coinShortName]
                          }
                          bgColor={
                            i === 0
                              ? '#b9a165'
                              : i === 1
                              ? '#be8862'
                              : i === 2
                              ? '#d38f0a'
                              : '#9092c2'
                          }
                        />
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h5 className="mb-0 text-black fs-20">
                Latest news from Moon Retriver
              </h5>
            </div>
            <div className="card-body">
              <div
                className="row"
                style={{ maxHeight: 350, overflowY: 'auto' }}
              >
                {moonRetrieverList &&
                  moonRetrieverList.length > 0 &&
                  moonRetrieverList.map((data, i) => {
                    return <LatestNews data={data} bgColor={'#5b5e81'} />;
                  })}
                {/* <LatestNews bgColor={'#5b5e81'} />
                <LatestNews bgColor={'#5b5e81'} />
                <LatestNews bgColor={'#5b5e81'} />
                <LatestNews bgColor={'#5b5e81'} />{' '}
                <LatestNews bgColor={'#5b5e81'} />
                <LatestNews bgColor={'#5b5e81'} />{' '}
                <LatestNews bgColor={'#5b5e81'} />
                <LatestNews bgColor={'#5b5e81'} />{' '}
                <LatestNews bgColor={'#5b5e81'} />
                <LatestNews bgColor={'#5b5e81'} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
