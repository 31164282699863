let IPUrl = 'http://182.72.203.245:1838/api/v1/';
let DomainURL = `${'https://node-cryptoanalysis.mobiloitte.org'}/api/v1/`;
let rugsniffURL = 'https://node-cryptoanalysis.mobiloitte.org/api/v1/rugsniff';
export const socketURL = 'wss://node-cryptoanalysis.mobiloitte.org';

let user = `${DomainURL}user`;
let token = `${DomainURL}token`;
let wallet = `${DomainURL}wallet`;

let message = `${DomainURL}message`;

const ApiConfig = {
  //USER
  addProject: `${user}/addProject  `,
  connectWallet: `${user}/connectWallet`,
  getProfile: `${user}/getProfile`,
  editProfile: `${user}/editProfile`,
  listProject: `${user}/listProject`,
  hottestScannedProject: `${user}/hottestScannedProject`,
  getMarketCapSymbol: `${user}/getMarketCapSymbol`,
  searchProject: `${user}/searchProject`,
  editProject: `${user}/editProject`,
  deleteProject: `${user}/deleteProject`,
  hottestScannedProjectByScore: `${user}/hottestScannedProjectByScore`,
  moonRetrieverList: `${user}/moonRetrieverList`,

  //TOKEN
  realTimePrice: `${token}/realTimePrice`,
  tokenDetails: `${token}/tokenDetails`,
  tokenPrice: `${token}/tokenPrice`,

  //WALLET
  addWallet: `${wallet}/addWallet`,
  listWallet: `${wallet}/listWallet`,

  //rugsniff
  getContractCode: `${rugsniffURL}/getContractCode`,
  addContractDetails: `${rugsniffURL}/addContractDetails`,
  liquidityScan: `${rugsniffURL}/liquidityScan`,
  listContractDetails: `${rugsniffURL}/listContractDetails`,
  holderScan: `${rugsniffURL}/holderScan`,
};

export default ApiConfig;
