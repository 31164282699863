import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { isBlank } from '../../CommonFunctions/';
import axios from 'axios';
import ApiConfig from '../../../config/ApiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CriculerLoader from '../CustomComponent/CircularLoader';

export default function AddWallet(props) {
  const accessToken = window.localStorage.getItem('accessToken');
  const [isUpdating, setIsUpdating] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkTranLoading, setCheckTranLoading] = useState(false);
  const notifyTopRight = (msg, type) => {
    toast[type](msg, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const addWalletHandler = async (type) => {
    setIsSubmit(true);
    setCheckTranLoading(false);
    if (walletAddress !== '' && type === 'add') {
      setIsUpdating(true);
      const status = await props.checkTranHandler([
        { walletAddress: walletAddress },
      ]);
      setCheckTranLoading(true);
      if (status) {
        try {
          const res = await axios.post(
            ApiConfig.addWallet,
            {
              walletAddress,
              projectId: props.selectedCoin._id,
            },
            {
              headers: {
                token: accessToken,
              },
            }
          );

          props.getWalletListHandler(props.selectedCoin);
          if (res.data.response_code === 200) {
            notifyTopRight(res.data.response_message, 'success');
            setWalletAddress('');
            setIsSubmit(false);
          } else {
            notifyTopRight(res.data.response_message, 'warn');
          }
          setIsUpdating(false);
          console.log('res', res);
        } catch (err) {
          notifyTopRight('failed', 'warn');
          setIsUpdating(false);
          console.log('ERROR', err);
        }
      }
      setIsUpdating(false);
      setTimeout(() => {
        setCheckTranLoading(false);
      }, 2000);
    }
    //  else if (walletAddress !== '') {
    //   setIsUpdating(true);

    //   await props.checkTranHandler([{ walletAddress: walletAddress }]);
    //   setIsUpdating(false);
    //   setCheckTranLoading(true);
    // }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Wallet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3">
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control"
                placeholder="Enter wallet address"
                name="contractAddress"
                value={walletAddress}
                onChange={(e) => {
                  setWalletAddress(e.target.value.trim());
                }}
                maxLength={50}
              />
              {isSubmit && isBlank(walletAddress) && (
                <p className="text-danger">Enter wallet address</p>
              )}
            </div>
          </div>
          {checkTranLoading && (
            <div className="mt-3">
              {props.isTranFound ? (
                <p>Transaction found</p>
              ) : (
                <span className="text-danger">No transaction found</span>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isUpdating}
            onClick={() => {
              addWalletHandler('add');
            }}
            style={{ display: 'flex' }}
          >
            Submit {isUpdating && <CriculerLoader />}
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
