import * as React from 'react';
import './index.css';
import Datafeed from './api/'
// import { widget } from "../../../public/charting_library";

// import { widget } from "../../charting_library";
import { widget } from "../../../charting_library";

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}



export function TVChartContainer({baseCurrency,quoteCurrency,tokenName,startDate,endDate }) {


	const [defaultProps, setDefaultProps] = React.useState({
		// symbol: 'Coinbase:BTC/USD',
		baseCurrency:'0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
		quoteCurrency:"0xD7B729ef857Aa773f47D37088A1181bB3fbF0099",
		symbol: 'PcSv1:DSCVR/USD',
		interval: '240',
		containerId: 'tv_chart_container',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
		endDate:endDate,
		startDate:startDate
	})	
	
	React.useEffect(()=>{
		if(tokenName.length>0 && quoteCurrency.length>0 && baseCurrency.length>0){
			localStorage.removeItem('quoteCurrency');
			localStorage.setItem('quoteCurrency', quoteCurrency);
			localStorage.removeItem('baseCurrency');
			localStorage.setItem('baseCurrency', baseCurrency);

			localStorage.removeItem('startDate');
			localStorage.setItem('startDate', startDate);
			localStorage.removeItem('endDate');
			localStorage.setItem('endDate', endDate);

			const temp = { ...defaultProps,startDate:startDate,endDate:endDate, symbol: `PcSv1:${tokenName}` ,quoteCurrency: quoteCurrency, baseCurrency:baseCurrency};
			setDefaultProps(temp);
		}
	},[tokenName,quoteCurrency,baseCurrency,startDate,endDate])
	

	React.useEffect(() => {	

		const widgetOptions = {
			theme:'dark',
			debug: false,
			symbol: `${defaultProps.symbol}`,
			datafeed: Datafeed,
			interval: defaultProps.interval,
			container_id: defaultProps.containerId,
			library_path: defaultProps.libraryPath,
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings','timeframes_toolbar','header_compare','header_saveload','header_undo_redo','header_settings','header_fullscreen_button','trading_notifications'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			
			studies_overrides: defaultProps.studiesOverrides,
			overrides: {
				// "mainSeriesProperties.showCountdown": true,
				"paneProperties.background": "#131722",
				"paneProperties.vertGridProperties.color": "#363c4e",
				"paneProperties.horzGridProperties.color": "#363c4e",
				"symbolWatermarkProperties.transparency": 90,
				"scalesProperties.textColor" : "#AAA",
				"mainSeriesProperties.candleStyle.wickUpColor": '#336854',
				"mainSeriesProperties.candleStyle.wickDownColor": '#7f323f',
			}
		};



		const tvWidgetBase = new widget(widgetOptions);
		let tvWidget = tvWidgetBase;
	
		tvWidget.onChartReady(() => {
		  tvWidget.headerReady().then(() => {
			const button = tvWidget.createButton();
			button.setAttribute("title", "Click to show a notification popup");
			button.classList.add("apply-common-tooltip");
			button.addEventListener("click", () =>
			  tvWidget.showNoticeDialog({
				title: "Notification",
				body: "TradingView Charting Library API works correctly",
				callback: () => {
				  console.log("Noticed!");
				},
			  })
			);
	
			button.innerHTML = "Check API";
		  });
		});

		return () => {
			if (tvWidget !== null) {
				tvWidget.remove();
				tvWidget = null;
			  }
		}
	}, [defaultProps])


	return (
		<div id={defaultProps.containerId} className={"TVChartContainer"} />
	)
}
