import React from 'react';
import profile from '../../../images/unnamed.png';

export default function LatestNews({ bgColor, data }) {
  return (
    <div className="col-lg-12">
      <div
        className="card  coin-holding-card"
        style={{ border: `1px solid ${bgColor}` }}
      >
        <div className="card-body rounded">
          <div className="d-flex align-items-center">
            <span>{data.title}</span>
          </div>
        </div>
        <div
          className="card-footer rounded d-flex justify-content-between border-0"
          style={{ backgroundColor: bgColor, minHeight: 100 }}
        >
          <div className="footer-info font-w600">
            <span className="text-white">{data.data}</span>
          </div>
          <div className="footer-info font-w600 ml-2">
            <img width="60" height='60' style={{borderRadius:"50%"}} src={data.image ? data.image : profile} alt={'ddd'} />
          </div>
        </div>
      </div>
    </div>
  );
}
