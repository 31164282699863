import React, { createContext, useState, useMemo, useEffect } from 'react';
import Web3 from 'web3';
import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem('accessToken');
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [userType, setUserType] = useState('USER');
  const accessToken = window.localStorage.getItem('accessToken');

  const connectToWallet = async () => {
    try {
      if (window.ethereum) {
        const web3 = (window.web3 = new Web3(window.ethereum));
        await window.ethereum.enable();
        const accounts = await web3.eth.getAccounts();
        setWalletAddress(accounts[0]);
        const networkId = await web3.eth.net.getId();
        await logInHandler(accounts[0]);
      } else {
        alert('Please login into Metamask');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      connectToWallet();
    }
  }, []);

  const getProfileHandler = async (accessToken) => {
    try {
      const res = await axios.get(ApiConfig.getProfile, {
        headers: {
          token: accessToken,
        },
      });
      console.log('resData', res);
      if (res.data.response_code === 200) {
        setUserData(res.data.result);
      } else {
        // getProfileHandler(localStorage.getItem('accessToken'));
      }
    } catch (err) {
      console.log('ERROR', err);
      getProfileHandler(localStorage.getItem('accessToken'));
    }
  };

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      getProfileHandler(localStorage.getItem('accessToken'));
    }
  }, []);

  const logInHandler = async (walletAddress) => {
    setIsLoading(true);
    try {
      const res = await axios.post(ApiConfig.connectWallet, {
        walletAddress: walletAddress,
      });
      if (res.data.response_code !== 200) {
      } else {
        console.log('res', res);
        setSession(res.data.result.token);
        setIsLogin(true);
        setUserType(res.data.result.userType);
        getProfileHandler(res.data.result.token);
      }
    } catch (err) {
      console.log('ERROR', err);
    }
    setIsLoading(false);
  };

  //ACCOUNT CHANGE
  useMemo(
    () =>
      window.ethereum &&
      window.ethereum.on('accountsChanged', async function (accounts) {
        if (accounts.length > 0) {
          window.location.reload();
        } else {
          localStorage.removeItem('accessToken');
          alert('Please Connect to wallet');
          setUserData({});
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //NETWORK CHANGE
  useMemo(() => {
    if (window.ethereum) {
      window.ethereum.on('networkChanged', async function (networkId) {
        // await connectToWallet();
        window.location.reload();
        // setNetworkId(networkId);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let data = {
    userLoggedIn: isLogin,
    userType: userType,
    walletAddress: walletAddress,
    getProfileHandler: () => getProfileHandler(),
    connectToWallet: () => connectToWallet(),
    userData,
    disconnectToWallet: () => {
      setSession(null);
      setIsLogin(false);
      setUserData({});
      setUserType('USER');
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
