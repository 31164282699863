import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, Nav, Tab } from 'react-bootstrap';
import axios from 'axios';
import ApiConfig from '../../../config/ApiConfig';
import moment from 'moment';
import Donut from '../kripton/MyWallets/Donut';
import { Link } from 'react-router-dom';
import { sortAddress } from '../../CommonFunctions/index';
import CriculerLoader from '../CustomComponent/CircularLoader';
import Web3 from 'web3';
import { AuthContext } from '../../../context/Auth';
import ContractABI from './ContractABI.json';
import ApeContractABI from './ApeContractABI.json';

const contractAdd = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73';
const ApeContractAddress = '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6';
const APIKEY = '5GNUKFSKU7CWXVN3ERWWX8N7GRIQC19SEG';
export default function RugStuff() {
  const web3 = (window.web3 = new Web3(window.ethereum));

  const auth = useContext(AuthContext);
  const accessToken = window.localStorage.getItem('accessToken');
  const [selectedDurationBtn, setSelectedDurationBtn] = useState('today');
  const [contractAddress, setContractAddress] = useState('');
  const [contractData, setContractData] = useState();
  const [isSniffITLoading, SetisSniffITLoading] = useState(false);
  const [isTrackItLoading, setIsTrackItLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [liquidityScan, setLiquidityScan] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [contractScan, setContractScan] = useState(0);
  // const [holderScan, setHolderScan] = useState(0);
  const [holderCount, setHolderCount] = useState(0);
  const [contractList, setContractList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [isErrorInContract, setIsErrorInContract] = useState(false);
  const [topTenHoldersSum, setTopTenHoldersSum] = useState(0);
  const [ownerAddress, setOwnerAddress] = useState('');
  const [notValid, setNotValid] = useState(false);
  const [totalDiviser, setTotalDiviser] = useState(0);
  const [isErrorInContractScan, setIsErrorInContractScan] = useState(false);
  // const getSingleValue = (param_value, type, real_value) => {
  //   const value = parseFloat(param_value);
  //   if (value >= 1 && value <= 3) {
  //     if (type === 'HIGH') {
  //       return 0.3;
  //     }
  //     if (type === 'MEDIUM') {
  //       return 0.2;
  //     }
  //     if (type === 'LOW') {
  //       return 0.15;
  //     }
  //   } else if (value >= 4 && value <= 9) {
  //     if (type === 'HIGH') {
  //       return 0.2;
  //     }
  //     if (type === 'MEDIUM' || type === 'LOW') {
  //       return 0.1;
  //     }
  //   } else if (value >= 9 && value <= 15) {
  //     return 0.5;
  //   } else {
  //     return real_value;
  //   }
  // };

  const getMoreHolders = async (pageNumber) => {
    try {
      const res = await axios.get(
        `https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=${contractAddress}&page=${pageNumber}&offset=10000&apikey=${APIKEY}`
      );

      let holdersList = res.data.result;
      return holdersList;
    } catch (error) {
      console.log('ERROR', error);
      return [];
    }
  };

  const getHoldersCount = async (address) => {
    setIsUpdatingData(true);
    setTopTenHoldersSum(0);
    setHolderCount(0);
    const web3 = (window.web3 = new Web3(window.ethereum));
    let totalHolderList = [];

    let hList = [];
    let pageNumber = 2;
    try {
      const res = await axios.get(
        `https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=${contractAddress}&page=1&offset=10000&apikey=${APIKEY}`
      );
      totalHolderList = hList = res.data.result;
      if (res.data.result.length == 10000) {
        while (hList.length == 10000) {
          hList = await getMoreHolders(pageNumber);
          pageNumber = pageNumber + 1;
          totalHolderList = totalHolderList.concat(hList);
        }
      }
      const holdersListSorted = await totalHolderList.sort((a, b) => {
        return (
          parseFloat(b.TokenHolderQuantity) - parseFloat(a.TokenHolderQuantity)
        );
      });
      let sum = 0;
      let count = 0;
      let length =
        holdersListSorted.length > 25 ? 25 : holdersListSorted.length;
      for (let i = 0; i < length; i++) {
        const isContract = await web3.eth.getCode(
          holdersListSorted[i].TokenHolderAddress
        );
        if (
          isContract === '0x' &&
          holdersListSorted[i].TokenHolderAddress.toLowerCase() !=
            address.toLowerCase() &&
          holdersListSorted[i].TokenHolderAddress.toLowerCase() !=
            contractAddress.toLowerCase() &&
          holdersListSorted[i].TokenHolderAddress.toLowerCase() !=
            '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F'.toLowerCase() &&
          holdersListSorted[i].TokenHolderAddress.toLowerCase() !=
            '0x10ED43C718714eb63d5aA57B78B54704E256024E'.toLowerCase() &&
          holdersListSorted[i].TokenHolderAddress.toLowerCase() !=
            '0x000000000000000000000000000000000000dead' &&
          holdersListSorted[i].TokenHolderAddress.toLowerCase() !=
            '0x0000000000000000000000000000000000000000'
        ) {
          count++;
          sum = sum + parseFloat(holdersListSorted[i].TokenHolderQuantity);
          if (count === 10) {
            break;
          }
        }
      }

      setTopTenHoldersSum(sum);
      setHolderCount(parseInt(totalHolderList.length));
      setIsUpdatingData(false);
    } catch (error) {
      setIsUpdatingData(false);

      console.log('ERROR', error);
    }
  };

  const getMaxTotalSupply = async (contractTest) => {
    setTotalSupply(0);

    setTotalDiviser(0);
    let diviser = 1e18;
    try {
      await contractTest.methods
        .decimals()
        .call({ from: auth.walletAddress })
        .then(async (res) => {
          diviser = '1'.padEnd(parseInt(res) + 1, 0);
        })
        .catch((error) => console.log('error', error));
    } catch (error) {
      console.log('ERROR', error);
    }

    try {
      const res = await axios.get(
        `https://api.bscscan.com/api?module=stats&action=tokensupply&contractaddress=${contractAddress}&apikey=${APIKEY}`
      );
      setTotalDiviser(res.data.result);
      setTotalSupply(parseFloat(res.data.result) / parseFloat(diviser));
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  const getLiquidityScanHandler = async () => {
    setOwnerAddress('');
    setNotValid(false);
    setLiquidityScan(0);
    if (auth.walletAddress !== '') {
      try {
        const contract = new web3.eth.Contract(ContractABI, contractAdd);

        await contract.methods
          .getPair(
            contractAddress,
            '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
          )
          .call({ from: auth.walletAddress })
          .then(async (result) => {
            if (result == '0x0000000000000000000000000000000000000000') {
              await getLiquidityScanHandlerAPE();
            } else {
              await liquidityScanHanlder(result);
            }
          });
      } catch (error) {
        await getLiquidityScanHandlerAPE();
        console.log('----ERROR', error);
      }
    } else {
    }
  };

  const getLiquidityScanHandlerAPE = async () => {
    try {
      const contract = new web3.eth.Contract(
        ApeContractABI,
        ApeContractAddress
      );

      await contract.methods
        .getPair(contractAddress, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c')
        .call({ from: auth.walletAddress })
        .then(async (result) => {
          await liquidityScanHanlder(result);
        });
    } catch (error) {
      console.log('----ERROR', error);
    }
  };

  const liquidityScanHanlder = async (result) => {
    console.log('RESULT', result);
    getMaxTotalSupply(0);
    getHoldersCount(result);
    try {
      const getABI = await axios.get(
        `https://api.bscscan.com/api?module=contract&action=getabi&address=${contractAddress}&apikey=${APIKEY}`
      );

      const contractTest = new web3.eth.Contract(
        JSON.parse(getABI.data.result),
        contractAddress
      );
      getMaxTotalSupply(contractTest);
      await contractTest.methods
        .owner()
        .call({ from: auth.walletAddress })
        .then(async (owner) => {
          setOwnerAddress(owner);
        })
        .catch((error) => console.log('error', error));
    } catch (error) {
      console.log('ERROR', error);
    }

    try {
      const res = await axios.get(
        `https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=${result}&page=1&offset=10000&apikey=${APIKEY}`
      );
      // if (res.data.status === '1') {
      const holdersData = res.data.result;
      const holdersListSorted = await holdersData.sort((a, b) => {
        return (
          parseFloat(b.TokenHolderQuantity) - parseFloat(a.TokenHolderQuantity)
        );
      });
      let maxAddress;
      let sum = 0;
      let length = holdersListSorted.length > 5 ? 5 : holdersListSorted.length;
      for (let i = 0; i < length; i++) {
        const isContract = await web3.eth.getCode(
          holdersListSorted[i].TokenHolderAddress
        );
        if (
          isContract != '0x' ||
          holdersListSorted[i].TokenHolderAddress.toLowerCase() ===
            '0x000000000000000000000000000000000000dead' ||
          holdersListSorted[i].TokenHolderAddress.toLowerCase() ===
            '0x0000000000000000000000000000000000000000'
        ) {
          maxAddress = holdersListSorted[i];
          const checkRes = await axios.get(
            `https://api.bscscan.com/api?module=contract&action=getabi&address=${maxAddress.TokenHolderAddress}&apikey=${APIKEY}`
          );
          if (checkRes.data.status !== '0') {
            const finalRes = await axios.get(
              `https://api.bscscan.com/api?module=stats&action=tokensupply&contractaddress=${result}&apikey=${APIKEY}`
            );
            const liquidityScanRes =
              (parseFloat(maxAddress.TokenHolderQuantity) /
                parseFloat(finalRes.data.result)) *
              100;
            sum = sum + liquidityScanRes;
            // setTotalSupply(parseFloat(finalRes.data.result) / 1e18);
          } else {
            const finalRes = await axios.get(
              `https://api.bscscan.com/api?module=stats&action=tokensupply&contractaddress=${result}&apikey=${APIKEY}`
            );
            const liquidityScanRes =
              (parseFloat(maxAddress.TokenHolderQuantity) /
                parseFloat(finalRes.data.result)) *
              100;
            sum = sum + liquidityScanRes;
            if (i === 0 && isContract !== '0x') {
              try {
                const res = await axios.get(ApiConfig.getContractCode, {
                  params: {
                    contractAddress: holdersListSorted[i].TokenHolderAddress,
                  },
                });
                console.log('res.data.responseResult', res);
                if (res.data.responseCode === 200) {
                  if (res.res.data.responseResult.sourceCode !== '') {
                    setNotValid(true);
                  }
                }
              } catch (error) {
                console.log('ERROR', error);
              }
            }
          }
        }
      }

      setLiquidityScan(parseFloat(sum).toFixed(2));
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  const checkContractHandler = async () => {
    if (contractAddress !== '') {
      try {
        setContractData();
        SetisSniffITLoading(true);
        setIsErrorInContract('');
        setIsErrorInContractScan(false);
        const res = await axios.get(ApiConfig.getContractCode, {
          params: {
            contractAddress: contractAddress,
          },
        });
        console.log('res.data.responseResult', res);
        if (res.data.responseCode === 200) {
          console.log('res.data.responseResult', res);

          let I = 0.5,
            H = 6,
            M = 3,
            L = 1,
            exl = 1,
            exH = 1,
            exM = 1,
            exL = 1;

          const ISC = parseFloat(
            res.data.responseResult.numberOfInformationalIssue
          );
          const HSC = parseFloat(res.data.responseResult.numberOfHighIssue);
          const MSC = parseFloat(res.data.responseResult.numberOfMediunIssue);
          const LSC = parseFloat(res.data.responseResult.numberOfLowIssue);

          if (
            parseFloat(res.data.responseResult.numberOfInformationalIssue) === 0
          ) {
            exl = 0;
          }

          if (parseFloat(res.data.responseResult.numberOfHighIssue) === 0) {
            exH = 0;
          }

          if (parseFloat(res.data.responseResult.numberOfMediunIssue) === 0) {
            exM = 0;
          }
          if (parseFloat(res.data.responseResult.numberOfLowIssue) === 0) {
            exL = 0;
          }

          let TC = ISC + HSC + MSC + LSC;
          console.log('TC', TC);

          let contractScan =
            100 -
            (((ISC / TC) * I +
              (HSC / TC) * H +
              (MSC / TC) * M +
              (LSC / TC) * L) /
              (I * exl + H * exH + M * exM + L * exL)) *
              100;

          console.log('contractScan', contractScan);

          setContractScan(parseFloat(contractScan).toFixed(2));
          setContractData(res.data.responseResult);
        } else {
          let resObj = {
            numberOfHighIssue: '0',
            numberOfInformationalIssue: '0',
            numberOfLowIssue: '0',
            numberOfMediunIssue: '0',
            numberOfOptimizationIssue: '0',
            sourceCode: '',
          };
          setIsErrorInContractScan(true);
          setContractScan(0);
          setContractData(resObj);
          if (res.data.responseMessage) {
            setContractScan(0);
            setIsErrorInContractScan(true);
            setContractData(resObj);
            setIsErrorInContract(res.data.responseMessage);
          } else {
            setContractScan(0);
            setIsErrorInContractScan(true);
            setContractData(resObj);
            setIsErrorInContract(res.data.responseMessage);
          }
        }
        SetisSniffITLoading(false);
      } catch (error) {
        let resObj = {
          numberOfHighIssue: '0',
          numberOfInformationalIssue: '0',
          numberOfLowIssue: '0',
          numberOfMediunIssue: '0',
          numberOfOptimizationIssue: '0',
          sourceCode: '',
        };

        setContractScan(0);
        setContractData(resObj);
        setIsErrorInContractScan(true);
        setIsErrorInContract(error.message);
        SetisSniffITLoading(false);
        console.log('ERROR', error);
      }
    } else {
      setIsError(true);
    }
  };

  const addContractDetailsHandler = async () => {
    if (contractData) {
      try {
        setIsTrackItLoading(true);
        const res = await axios.post(
          ApiConfig.addContractDetails,
          {
            contractAddress: contractAddress,
            numberOfOptimizationIssue: contractData.numberOfOptimizationIssue,
            numberOfInformationalIssue: contractData.numberOfInformationalIssue,
            numberOfLowIssue: contractData.numberOfLowIssue,
            numberOfMediunIssue: contractData.numberOfMediunIssue,
            numberOfHighIssue: contractData.numberOfHighIssue,
            maxSupply: totalSupply,
            holders: holderCount,
            contractScan: contractScan,
            holdersScan:
              topTenHoldersSum && totalSupply
                ? parseFloat((topTenHoldersSum / totalSupply) * 100).toFixed(2)
                : 0,
            liquidityScan: liquidityScan,
          },
          {
            headers: {
              token: accessToken,
            },
          }
        );
        setIsTrackItLoading(false);
        listContractDetailsHandler();
      } catch (error) {
        setIsTrackItLoading(false);
        console.log('error', error);
      }
    }
  };

  const listContractDetailsHandler = async () => {
    try {
      setIsLoading(true);
      setContractList();
      const res = await axios.get(ApiConfig.listContractDetails, {
        params: {
          type: selectedDurationBtn,
        },
        headers: {
          token: accessToken,
        },
      });
      if (res.data.response_code === 200) {
        setContractList(res.data.result);
      } else {
        setContractList([]);
      }
      setIsLoading(false);
    } catch (error) {
      listContractDetailsHandler();
      console.log('error', error);
    }
  };

  useEffect(() => {
    listContractDetailsHandler();
  }, [selectedDurationBtn]);

  const handleSaveToPCAll = () => {
    const fileData = `${JSON.parse(contractData.sourceCode)}`;
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${'contract'}.sol`;
    link.href = url;
    link.click();
  };

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-6'>
              <div
                className='input-group search-area-2 mb-1 mr-auto d-inline-flex'
                style={{ width: '100%', overflow: 'unset' }}
              >
                <div className='input-group-append'>
                  <Link to='/transactions' className='input-group-text'>
                    <i className='flaticon-381-search-2' />
                  </Link>
                </div>
                <input
                  value={contractAddress}
                  onChange={(e) => setContractAddress(e.target.value.trim())}
                  type='text'
                  className='form-control'
                  placeholder='Enter contract address or Solidity contract'
                />
              </div>
              {isError && contractAddress === '' && (
                <p className='ml-3' style={{ color: 'red' }}>
                  Please enter address
                </p>
              )}
            </div>
            <div className='col-sm-3'></div>
            <div
              className='col-sm-3 d-flex'
              style={{ justifyContent: 'center' }}
            >
              <button
                className='btn btn-primary btn-rounded pl-5 pr-5'
                onClick={() => {
                  checkContractHandler();
                  getLiquidityScanHandler();
                  // getHolderScanHandler();
                  // getHoldersCount();
                  // getMaxTotalSupply();
                  // addAdminHandler();
                }}
                style={{
                  backgroundColor: 'white',
                  color: '#363062',
                }}
                disabled={isSniffITLoading || isUpdatingData}
              >
                <span className='d-flex'>
                  SNIFF-IT!{' '}
                  {(isSniffITLoading || isUpdatingData) && <CriculerLoader />}
                </span>
              </button>
              {/* <Link to="/my-wallets" className="btn btn-primary btn-rounded">
            Add Project
          </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-12 col-xxl-12'>
          <div className='card'>
            <div className='card-body'>
              {isErrorInContractScan && (
                <div className='row'>
                  <div className='col-xl-12 col-xxl-12'>
                    <h4 className='text-center mb-5' style={{ color: 'red' }}>
                      {'Impossible to scan the smart contract code'}
                    </h4>
                  </div>
                </div>
              )}
              {contractData && !isUpdatingData ? (
                <div className='row'>
                  <div className='col-xl-6 col-xxl-6'>
                    <div className='mb-2'>
                      <p className='mb-1'>Contract Address</p>
                      <h4 className='text-black'>
                        {contractData && sortAddress(contractAddress)}
                      </h4>
                    </div>
                    <div className='mb-2'>
                      <p className='mb-1'>Ownership Renouncement </p>
                      {ownerAddress ==
                      '0x0000000000000000000000000000000000000000' ? (
                        <h4 className='text-green'>Renounced</h4>
                      ) : (
                        <h4 className='text-red'>Not Renounced</h4>
                      )}
                    </div>

                    <div className='mb-2'>
                      <p className='mb-1'>Max Total Supply</p>
                      <h4 className='text-black'>
                        {totalSupply && Math.ceil(totalSupply)}
                      </h4>
                    </div>
                    <div className='mb-2'>
                      <p className='mb-1'>Holders</p>
                      <h4 className='text-black'>{holderCount} holder</h4>
                    </div>
                    {!isErrorInContractScan && (
                      <>
                        <div className='mb-2'>
                          <p className='mb-1'>High Issue</p>
                          <h4 className='text-red'>
                            {contractData && contractData.numberOfHighIssue}
                          </h4>
                        </div>
                        <div className='mb-2'>
                          <p className='mb-1'>Mediun Issue</p>
                          <h4 className='text-orange'>
                            {contractData && contractData.numberOfMediunIssue}
                          </h4>
                        </div>
                        <div className='mb-2'>
                          <p className='mb-1'>Low Issue</p>
                          <h4 className='text-green'>
                            {contractData && contractData.numberOfLowIssue}
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='col-xl-6 col-xxl-6'>
                    <p className='fs-24 text-center mb-5'>Rug-Sniff Result</p>
                    <div className='row'>
                      <div className='col-sm-4 mb-sm-0 mb-4 text-center'>
                        <div className='d-inline-block position-relative donut-chart-sale mb-3'>
                          <Donut
                            value={contractScan}
                            backgroundColor='#FF6826'
                          />

                          <small className='text-black'>{contractScan}%</small>
                        </div>
                        <h5 className='fs-18 text-black'>Contract Scan</h5>
                        {/* <span>$10,000</span> */}
                        {/* <Dropdown className="dropdown ml-auto">
                        <Dropdown.Toggle
                          variant=""
                          className="i-false p-0 btn-link"
                          data-toggle="dropdown"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <circle fill="#000000" cx={12} cy={5} r={2} />
                              <circle fill="#000000" cx={12} cy={12} r={2} />
                              <circle fill="#000000" cx={12} cy={19} r={2} />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="dropdown-menu dropdown-menu-right"
                          alignRight={true}
                        >
                          <Link className="dropdown-item">Edit</Link>
                          <Link className="dropdown-item">Delete</Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    */}
                      </div>
                      <div className='col-sm-4 mb-sm-0 mb-4 text-center'>
                        <div className='d-inline-block position-relative donut-chart-sale mb-3'>
                          <Donut
                            value={
                              topTenHoldersSum &&
                              totalDiviser &&
                              parseFloat(
                                (topTenHoldersSum / totalDiviser) * 100
                              ).toFixed(2)
                            }
                            backgroundColor='#1DC624'
                          />

                          <small className='text-black'>
                            {topTenHoldersSum &&
                              totalDiviser &&
                              parseFloat(
                                (topTenHoldersSum / totalDiviser) * 100
                              ).toFixed(2)}
                            %
                          </small>
                        </div>
                        <h5 className='fs-18 text-black'>Holders Scan</h5>
                        <p>TOP 10 holders own</p>
                        {/* <span>$500</span> */}
                        {/* <Dropdown className="dropdown ml-auto">
                        <Dropdown.Toggle
                          variant=""
                          className="i-false p-0 btn-link"
                          data-toggle="dropdown"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <circle fill="#000000" cx={12} cy={5} r={2} />
                              <circle fill="#000000" cx={12} cy={12} r={2} />
                              <circle fill="#000000" cx={12} cy={19} r={2} />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="dropdown-menu dropdown-menu-right"
                          alignRight={true}
                        >
                          <Link className="dropdown-item">Edit</Link>
                          <Link className="dropdown-item">Delete</Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    */}
                      </div>
                      <div className='col-sm-4 text-center'>
                        <div className='d-inline-block position-relative donut-chart-sale mb-3'>
                          <Donut
                            value={liquidityScan && liquidityScan}
                            backgroundColor='#9E9E9E'
                          />

                          <small className='text-black'>
                            {liquidityScan && liquidityScan}%
                          </small>
                        </div>
                        <h5 className='fs-18 text-black'>Liquidity Scan</h5>
                        {notValid && (
                          <p>
                            Highest LP holder address is not from a verified
                            source
                          </p>
                        )}
                        {/* <span>$100</span> */}

                        {/* <Dropdown className="dropdown ml-auto">
                        <Dropdown.Toggle
                          variant=""
                          className="i-false p-0 btn-link"
                          data-toggle="dropdown"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <circle fill="#000000" cx={12} cy={5} r={2} />
                              <circle fill="#000000" cx={12} cy={12} r={2} />
                              <circle fill="#000000" cx={12} cy={19} r={2} />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="dropdown-menu dropdown-menu-right"
                          alignRight={true}
                        >
                          <Link className="dropdown-item">Edit</Link>
                          <Link className="dropdown-item">Delete</Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    */}
                      </div>
                    </div>
                    <div className='text-right mt-4 mb-2'>
                      {contractData && contractData.sourceCode && (
                        <button
                          onClick={() => handleSaveToPCAll()}
                          className='btn btn-primary btn-rounded mr-2'
                          // style={{ display: 'block' }}
                        >
                          <span style={{ display: 'flex' }}>Download Code</span>
                        </button>
                      )}
                      <button
                        disabled={isTrackItLoading || !contractData}
                        onClick={() => addContractDetailsHandler()}
                        className='btn btn-primary btn-rounded'
                        // style={{ display: 'block' }}
                      >
                        <span style={{ display: 'flex' }}>
                          Track-it!{isTrackItLoading && <CriculerLoader />}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='row'>
                  <div className='col-xl-12 col-xxl-12'>
                    {isErrorInContract && isErrorInContract !== '' ? (
                      <h4 className='text-center' style={{ color: 'red' }}>
                        {isErrorInContract}
                      </h4>
                    ) : isSniffITLoading || isUpdatingData ? (
                      <h4 className='text-center'>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <div class='loader'></div>
                        </div>
                      </h4>
                    ) : (
                      <h4 className='text-center'>
                        Please enter contract address and click on 'SNIFF-IT!'
                        button to see details
                      </h4>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-12 col-xxl-12'>
          <div className='card'>
            <div className='card-header d-block d-sm-flex border-0 pb-0'>
              <h5 className='fs-18 '>My Latest Project Sniffed</h5>
              <div>
                <button
                  onClick={() => setSelectedDurationBtn('monthly')}
                  className={`btn ${
                    selectedDurationBtn === 'monthly' && 'btn-primary'
                  } btn-rounded`}
                >
                  Last Month
                </button>
                <button
                  onClick={() => setSelectedDurationBtn('weekly')}
                  className={`btn ${
                    selectedDurationBtn === 'weekly' && 'btn-primary'
                  } btn-rounded`}
                >
                  Last 7 Days
                </button>
                <button
                  onClick={() => setSelectedDurationBtn('today')}
                  className={`btn ${
                    selectedDurationBtn === 'today' && 'btn-primary'
                  } btn-rounded`}
                >
                  Today
                </button>
              </div>
            </div>
            <div className='card-body pt-2'>
              <div className='table-responsive'>
                <table className='table shadow-hover card-table border-no'>
                  <tbody>
                    <tr>
                      <th style={{ width: 150, maxWidth: 150 }}>
                        <span className='font-w600 text-black'>
                          Overall Score
                        </span>
                      </th>
                      <th>
                        <span className='font-w600 text-black'>
                          Project/Coin
                        </span>
                      </th>
                      <th>
                        <span className='font-w600 text-black'>
                          Last Checked
                        </span>
                      </th>
                      <th>
                        <span className='font-w600 text-black'>
                          Contract Address
                        </span>
                      </th>
                      <th>
                        <span className='font-w600 text-black'>Website</span>
                      </th>
                    </tr>
                    {contractList &&
                      contractList.map((data, i) => {
                        let overAllScore =
                          (parseFloat(data.contractScan) +
                            parseFloat(data.holdersScan) +
                            parseFloat(data.liquidityScan)) /
                          3;
                        return (
                          <tr>
                            <td
                              className='text-center'
                              style={{ padding: '12px 9px' }}
                            >
                              <span
                                className='activity-icon pt-1'
                                style={{ width: 90 }}
                              >
                                {parseFloat(overAllScore).toFixed(2)}
                              </span>
                            </td>
                            <td>
                              <span className='font-w600 text-black'></span>
                            </td>
                            <td>
                              <span className='text-black'>
                                {' '}
                                {moment(data.updatedAt).format(
                                  'hh:mm:ss A'
                                )}{' '}
                              </span>
                            </td>
                            <td>
                              <span className='font-w600 text-black'>
                                {sortAddress(data.contractAddress)}
                              </span>
                            </td>
                            <td>
                              <Link
                                className='btn-link text-light float-right'
                                to='/dashboard'
                              >
                                -
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    {contractList && contractList.length === 0 && (
                      <tr>
                        <td colSpan={5} style={{ textAlign: 'center' }}>
                          <h4>No Data found</h4>
                        </td>
                      </tr>
                    )}
                    {isLoading && (
                      <tr>
                        <td
                          colSpan={5}
                          style={{ textAlign: 'center', padding: 40 }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div class='loader'></div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
