import React from 'react';
import { Link } from 'react-router-dom';
import { sortAmount } from '../../CommonFunctions/index';
export default function CoinDetailsCard({
  data,
  bgColor,
  coinOtherDetails,
  coinBuySellPrice,
  coinPrice,
}) {
  return (
    <div className="col-lg-6">
      <div
        className="card  coin-holding-card"
        style={{ border: `1px solid ${bgColor}` }}
      >
        <div className="card-body rounded" style={{ backgroundColor: bgColor }}>
          <div className="d-flex align-items-center">
            <div>
              <h6
                className="font-w500 text-white title mb-0"
                style={{ fontSize: 22 }}
              >
                {data.coinName}
              </h6>
              <span className="text-white fs-14 op7">{data.coinShortName}</span>
            </div>
          </div>
          <div className="d-flex flex-wrap mt-4 align-items-center">
            <div className="d-flex align-items-center mr-auto pr-3 mb-2">
              <svg
                className="mr-3"
                width={42}
                height={26}
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="3.42856"
                  height="25.1428"
                  rx="1.71428"
                  transform="matrix(-1 0 0 1 24 0)"
                  fill="white"
                />
                <rect
                  width="3.42856"
                  height="18.2856"
                  rx="1.71428"
                  transform="matrix(-1 0 0 1 17.1431 6.85712)"
                  fill="white"
                />
                <rect
                  width="3.42856"
                  height="7.99997"
                  rx="1.71428"
                  transform="matrix(-1 0 0 1 10.2861 17.1428)"
                  fill="white"
                />
                <rect
                  width="3.86812"
                  height="15.4725"
                  rx="1.93406"
                  transform="matrix(-1 0 0 1 3.86816 9.67029)"
                  fill="white"
                />
              </svg>
              <h4 className="font-w500 text-white amount mb-0">
                $
                {coinPrice &&
                coinPrice.coinPrice.TokenPrice &&
                parseFloat(coinPrice.coinPrice.TokenPrice).toFixed(6) < 1e-8
                  ? sortAmount(
                      parseFloat(coinPrice.coinPrice.TokenPrice).toString()
                    )
                  : coinPrice &&
                    coinPrice.coinPrice.TokenPrice &&
                    coinPrice.coinPrice.TokenPrice &&
                    parseFloat(coinPrice.coinPrice.TokenPrice).toFixed(6)}
                {/* {coinOtherDetails &&
                  parseFloat(coinPrice.coinPrice.TokenPrice).toFixed(6)} */}
              </h4>
            </div>
            <div
              className="mb-2"
              style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              {coinBuySellPrice &&
              coinBuySellPrice.sevenDaysChnage &&
              parseFloat(coinBuySellPrice.sevenDaysChnage).toFixed(2) > 0 ? (
                <i
                  class="fa fa-sort-asc"
                  aria-hidden="true"
                  style={{ color: '#61C277', fontSize: 26, paddingTop: 11 }}
                ></i>
              ) : (
                <i
                  class="fa fa-sort-desc"
                  aria-hidden="true"
                  style={{ color: 'red', fontSize: 26, paddingBottom: 11 }}
                ></i>
              )}

              <span className="text-white fs-14 px-1">
                {coinBuySellPrice &&
                  coinBuySellPrice.sevenDaysChnage &&
                  Math.abs(
                    parseFloat(coinBuySellPrice.sevenDaysChnage).toFixed(2)
                  )}
                % This Week
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-between border-0">
          <div>
            <div className="footer-info font-w600">
              <span className="text-success px-1">BUY</span>
              <span className="text-black px-1 d-block">
                $
                {coinBuySellPrice &&
                coinBuySellPrice.buyPrice &&
                parseFloat(coinBuySellPrice.buyPrice).toFixed(6) < 1e-8
                  ? sortAmount(parseFloat(coinBuySellPrice.buyPrice).toString())
                  : coinBuySellPrice &&
                    coinBuySellPrice.buyPrice &&
                    parseFloat(coinBuySellPrice.buyPrice).toFixed(6)}
                {/* {coinBuySellPrice &&
                  parseFloat(coinBuySellPrice.buyPrice).toFixed(8)} */}
              </span>
            </div>
            <div className="footer-info font-w600">
              <span className="text-danger px-1">SELL</span>
              <span className="text-black px-1 d-block">
                $
                {coinBuySellPrice &&
                coinBuySellPrice.sellPrice &&
                parseFloat(coinBuySellPrice.sellPrice).toFixed(6) < 1e-8
                  ? sortAmount(
                      parseFloat(coinBuySellPrice.sellPrice).toString()
                    )
                  : coinBuySellPrice &&
                    coinBuySellPrice.sellPrice &&
                    parseFloat(coinBuySellPrice.sellPrice).toFixed(6)}
                {/* {coinBuySellPrice &&
                  parseFloat(coinBuySellPrice.sellPrice).toFixed(8)} */}
              </span>
            </div>
          </div>
          <Link
            to={{
              pathname: '/insight-kpi',
              state: data,
            }}
            className="underline text-primary font-w500 footer-info"
          >
            More Details
          </Link>
        </div>
      </div>
    </div>
  );
}
