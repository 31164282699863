import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
/// Scroll
import PerfectScrollbar from 'react-perfect-scrollbar';

/// Image
import profile from '../../../images/profile/pic1.jpg';
import avatar from '../../../images/avatar/1.jpg';
import { Dropdown } from 'react-bootstrap';
import { AuthContext } from '../../../context/Auth';
import { useContext } from 'react';
import { sortWalletAddress } from '../../CommonFunctions/index';
const Header = ({ onNote }) => {
  const [isConnect, setIsConnect] = useState(false);
  const auth = useContext(AuthContext);
  useEffect(() => {
    setIsConnect(auth.userLoggedIn);
  }, [auth.userLoggedIn]);
  var path = window.location.pathname.split('/');
  var name = path[path.length - 1].split('-');
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes('app')
    ? filterName.filter((f) => f !== 'app')
    : filterName.includes('ui')
    ? filterName.filter((f) => f !== 'ui')
    : filterName.includes('uc')
    ? filterName.filter((f) => f !== 'uc')
    : filterName.includes('basic')
    ? filterName.filter((f) => f !== 'basic')
    : filterName.includes('jquery')
    ? filterName.filter((f) => f !== 'jquery')
    : filterName.includes('table')
    ? filterName.filter((f) => f !== 'table')
    : filterName.includes('page')
    ? filterName.filter((f) => f !== 'page')
    : filterName.includes('email')
    ? filterName.filter((f) => f !== 'email')
    : filterName.includes('ecom')
    ? filterName.filter((f) => f !== 'ecom')
    : filterName.includes('chart')
    ? filterName.filter((f) => f !== 'chart')
    : filterName.includes('editor')
    ? filterName.filter((f) => f !== 'editor')
    : filterName;
  // console.log(finalName.join(" "));
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: 'capitalize' }}
              >
                {finalName.join(' ').length === 0
                  ? 'Dashboard'
                  : finalName.join(' ') === 'dashboard dark'
                  ? 'Dashboard'
                  : finalName.join(' ') === 'insight kpi'
                  ? 'Insight & KPI'
                  : finalName.join(' ')}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown "
              >
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link  ai-icon i-false c-pointer"
                  // href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <i className="flaticon-381-ring" />
                  <div className="pulse-css" />
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="mt-2">
                  <PerfectScrollbar className="widget-media dz-scroll p-3 height380 ps">
                    <ul className="timeline">
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2">
                            <img alt="images" width={50} src={avatar} />
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Dr sultads Send you Photo</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-info">KG</div>
                          <div className="media-body">
                            <h6 className="mb-1">
                              Resport created successfully
                            </h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-success">
                            <i className="fa fa-home" />
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Reminder : Treatment Time!</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2">
                            <img alt="image" width={50} src={avatar} />
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Dr sultads Send you Photo</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-danger">KG</div>
                          <div className="media-body">
                            <h6 className="mb-1">
                              Resport created successfully
                            </h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-primary">
                            <i className="fa fa-home" />
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Reminder : Treatment Time!</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                      <div
                        className="ps__thumb-x"
                        tabIndex={0}
                        style={{ left: 0, width: 0 }}
                      />
                    </div>
                    <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                      <div
                        className="ps__thumb-y"
                        tabIndex={0}
                        style={{ top: 0, height: 0 }}
                      />
                    </div>
                  </PerfectScrollbar>
                  <Link className="all-notification" to="#">
                    See all notifications <i className="ti-arrow-right" />
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
              {/* <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown "
              >
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link bell bell-link i-false c-pointer"
                  onClick={() => onNote()}
                >
                  <i className="fa fa-commenting-o"></i>
                  <div className="pulse-css" />
                </Dropdown.Toggle>
              </Dropdown> */}

              <li className="nav-item dropdown header-profile">
                <div
                  className="connectWallet-btn"
                  style={{
                    backgroundColor: isConnect ? 'white' : 'lightgray',
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    await auth.connectToWallet();
                    // setIsConnect(true);
                  }}
                >
                  <div
                    className="connectWallet-btn-innerDiv"
                    style={
                      auth.userLoggedIn
                        ? { backgroundColor: '#68CF29' }
                        : { backgroundColor: 'gray' }
                    }
                  ></div>
                  <span
                    className="connectWallet-text"
                    style={{ position: 'absolute' }}
                  >
                    {isConnect
                      ? auth.walletAddress !== '' &&
                        sortWalletAddress(auth.walletAddress)
                      : 'Connect'}
                  </span>
                </div>
              </li>
              <li className="nav-item dropdown header-profile ">
                <i
                  className="fa fa-power-off"
                  style={{ fontSize: 30, cursor: 'pointer' }}
                  aria-hidden="true"
                  onClick={() => auth.disconnectToWallet()}
                ></i>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
