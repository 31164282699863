import React from 'react';
import { TVChartContainer } from '../TVChartContainer';
export default function GraphComponent({
  quoteCurrency,
  shotName,
  startDate,
  endDate,
}) {
  if (quoteCurrency && quoteCurrency !== '' && quoteCurrency.length > 10) {
    return (
      <TVChartContainer
        baseCurrency={'0x55d398326f99059ff775485246999027b3197955'}
        quoteCurrency={quoteCurrency}
        tokenName={`${shotName}/USD`}
        startDate={startDate}
        endDate={endDate}
      />
    );
  } else {
    return (
      <TVChartContainer
        baseCurrency={'0x55d398326f99059ff775485246999027b3197955'}
        quoteCurrency={'0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82'}
        tokenName={`${shotName}/USD`}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }
}
