import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ApiConfig from '../../../config/ApiConfig';
import CriculerLoader from '../CustomComponent/CircularLoader';
import { ToastContainer, toast } from 'react-toastify';

export default function DeleteProjectPopUp(props) {
  const accessToken = window.localStorage.getItem('accessToken');
  const [isUpdating, setIsUpdating] = useState(false);

  const deleteProjectHandler = async () => {
    setIsUpdating(true);
    try {
      const res = await axios({
        method: 'DELETE',
        url: ApiConfig.deleteProject,
        data: {
          _id: props.id,
        },
        headers: {
          token: accessToken,
        },
      });
      setIsUpdating(false);
      props.projectListHandler();
      if (res.data.response_code === 200) {
        console.log('res', res);
        notifyTopRight(res.data.response_message, 'success');
        props.onHide();
      } else {
        notifyTopRight(res.data.response_message, 'warn');
      }
    } catch (err) {
      setIsUpdating(false);
      console.log('ERROR', err);
    }
  };

  const notifyTopRight = (msg, type) => {
    console.log('msg', msg, type);
    toast[type](msg, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <>
      <ToastContainer />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isUpdating}
            onClick={() => {
              deleteProjectHandler();
            }}
            style={{ display: 'flex' }}
          >
            Submit
            {isUpdating && <CriculerLoader />}
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
